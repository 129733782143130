/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
/**
 *
 * AddTeamLeadInput
 *
 */
import React, { useState, useEffect, ReactNode } from "react";
import { TextField } from "@material-ui/core";
import { Autocomplete, createFilterOptions, Alert } from "@material-ui/lab";
import { isValidEmail } from "utils/helper_functions/isValidEmail";
import { Avatar } from "../Avatar/Loadable";
import { Media } from "react-bootstrap";

interface Props {
  usersList?: Users[] | null;
  allowedDomains: string[];
  leadValue: string;
  setLeadValue: (value: string) => void;
  leadValidationError: string;
  setLeadValidationError: (value: string) => void;
}

interface Users {
  emailAddress: string;
  firstName: string;
  lastName: string;
}

interface Option {
  label: string;
  value: string;
}

export function AddTeamLeadInput({
  usersList,
  allowedDomains,
  leadValue,
  setLeadValue,
  leadValidationError,
  setLeadValidationError,
}: Props) {
  /* State Variables */
  const [options, setOptions] = useState([{ label: "", value: "" }]);
  const [originalOptions, setOriginalOptions] = useState([
    { label: "", value: "" },
  ]);
  const [value, setValue] = useState<any>(null);
  const [inputValue, setInputValue] = useState("");
  const [openPopup, setOpenPopup] = useState(false);
  const [tempInputValue, setTempInputValue] = useState("");
  const [valueToDisplay, setValueToDisplay] = useState("");

  /****************************************************************************
   * Filter Options                                                           *
   ****************************************************************************/

  // we need to set this value so that we can filter by both label and value of the options
  // object.  this way, if the option is an accepted user with a name, we can filter by both
  // email and name.
  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option: any) => {
      return `${option.label} ${option.value}`;
    },
  });

  /***************************************************************************
   * Hooks                                                                   *
   ***************************************************************************/

  // when first coming to the component, we need to create a list of options that
  // will be displayed in the dropdown.  this list needs to be composed as an array
  // of options in the shape {label: _, value: _}.
  useEffect(() => {
    let optionList: any = usersList
      ?.slice()
      .sort((a, b) => {
        if (a.firstName && b.firstName)
          return a.firstName.localeCompare(b.firstName);
        else return a.emailAddress.localeCompare(b.emailAddress);
      })
      .map((user) => {
        const option = {
          label: user.firstName
            ? `${user.firstName} ${user.lastName}`
            : user.emailAddress,
          value: user.emailAddress,
        };
        if (leadValue === option.value) return null;
        return option;
      });
    if (optionList) {
      // we want to have an "active" options list of the options that are currently
      // eligible to be displayed, and an original one that we can compare against
      // when options are selected (see the useEffect immediately below this).
      setOptions(optionList);
      setOriginalOptions(optionList);
    }
    // adding options to the dependency array is required by eslint, but that
    // leds to this function being called inifinitely.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersList]);

  // this useEffect will remove any selected values from, or return them to, the options list
  // when a value is selected or removed from the dropdown list or input.
  useEffect(() => {
    if (value) {
      // create arrays to hold the upcoming values. this allows us to not have a nest
      // map, in case the list of options is large.
      const filteredOptions: Option[] = [];

      // map over the original list of options to determine if we need to remove, or add (in
      // the case that an option is removed from the input), the option from the current list
      // of options.
      originalOptions.map((option) => {
        if (
          option.value.toLowerCase().includes(value.value.toLowerCase()) ||
          option.label.toLowerCase().includes(value.value.toLowerCase())
        ) {
          filteredOptions.push(option);
        }
      });
      const valueToShow = `${value.label} ${value.value}`;
      setOptions(filteredOptions);
    } else {
      // if value is empty, we want to display all of the original options
      setOptions(originalOptions);
    }
  }, [value, originalOptions]);

  /****************************************************************************
   * Handlers
   ****************************************************************************/
  const handleKeyPress = (key) => {
    // because we are displaying selected values with a different format (it includes
    // the name and email address),when the user hits backspace to delete selected events,
    // we handle that here.  If it is a typed value, backspace works normally.
    // TODO: less hacky way of doing this maybe?
    if (key === "Backspace" && valueToDisplay.includes(")")) {
      setValue(null);
      setValueToDisplay("");
      setInputValue("");
      setLeadValue("");
      setLeadValidationError("");
    }

    // if the user is typing in an email address and presses the "Enter" button
    // we want to validate the current entry and then add it to the email addresses.
    if (key === "Enter" && value) {
      if (isValidEmail(value.value)) {
        if (!allowedDomains.includes(value.value.split("@")[1].toLowerCase())) {
          setLeadValidationError(
            `${value.value} is not included in your company domain.`
          );
        }
        setLeadValue(value.value);
        setTempInputValue("");
        setOpenPopup(false);
      } else {
        setOpenPopup(false);
        const newErrorObject: any = {
          [value.value]: "is not an email address.",
        };
        setLeadValidationError(`${value.value} is not an email address.`);
      }
    }
  };
  return (
    <div className="mb-0">
      <Autocomplete
        id="email-autocomplete-lead"
        inputValue={inputValue}
        value={value}
        options={options}
        getOptionLabel={(option) => `${option.label.toString()}`}
        filterOptions={filterOptions}
        freeSolo
        onChange={(event, newValue, reason) => {
          if (reason === "select-option") {
            setValue(newValue);
            setInputValue(newValue.value);
            setLeadValue(newValue.value);
          }
          if (reason === "clear") {
            setValue(null);
            setInputValue("");
            setLeadValue("");
            setLeadValidationError("");
          }
        }}
        onInputChange={(event, newInputValue, reason) => {
          // because we want to display selected values in the format: {name}({emailAddress})
          // we need to get that information from the usersList and format it appropriately.
          // once formatted, we save that value to state so that we can use it independently
          // of the value.
          const selectedUser = usersList?.filter((user) => {
            const userName = `${user.firstName} ${user.lastName}`;
            return (
              user?.emailAddress === newInputValue || userName === newInputValue
            );
          });
          // this is the name we want to display in the input
          const nameToDisplay =
            selectedUser && selectedUser.length > 0 && selectedUser[0].firstName
              ? `${selectedUser[0].firstName} ${selectedUser[0].lastName} (${selectedUser[0].emailAddress})`
              : newInputValue;

          // this is the object we need to save so that the autocomplete works correctly.
          const entryObject = {
            label: newInputValue,
            value: newInputValue,
          };

          setValueToDisplay(nameToDisplay);
          setValue(entryObject);
          setInputValue(newInputValue);

          // in the case of selected options, we only want to send the emailAddress to the
          // API, so we need to set the leadValue state variable correctly.
          const leadValueToUse =
            selectedUser &&
            selectedUser.length > 0 &&
            selectedUser[0].emailAddress
              ? selectedUser[0].emailAddress
              : newInputValue;
          setLeadValue(leadValueToUse);
        }}
        renderOption={(option) => {
          const selectedUser = usersList?.filter(
            (user) =>
              user?.emailAddress === option?.value ||
              `${user?.firstName} ${user?.lastName}` === option?.label
          );
          if (selectedUser && selectedUser.length > 0) {
            return (
              <Media>
                <div className="align-self-start mr-3 mt-1">
                  <Avatar
                    height={40}
                    width={40}
                    fontSize={20}
                    userName={
                      selectedUser && selectedUser[0].firstName
                        ? `${selectedUser[0]?.firstName} ${selectedUser[0]?.lastName}`
                        : ""
                    }
                    initials={
                      selectedUser && selectedUser[0]?.firstName
                        ? `${selectedUser[0]?.firstName
                            .charAt(0)
                            .toUpperCase()} ${selectedUser[0]?.lastName
                            .charAt(0)
                            .toUpperCase()}`
                        : null
                    }
                    emailAddress={
                      selectedUser ? selectedUser[0]?.emailAddress : ""
                    }
                    backgroundColor={`#2dccd3`}
                  />
                </div>
                <Media.Body>
                  <p className="dropdown-text mb-0 mt-2">
                    {selectedUser[0]?.firstName} {selectedUser[0]?.lastName}
                  </p>
                  <p
                    className={`${
                      selectedUser[0]?.firstName
                        ? "dropdown-text-muted  mt-0"
                        : "dropdown-text mt-0"
                    }`}
                  >
                    {selectedUser[0]?.emailAddress}
                  </p>
                </Media.Body>
              </Media>
            );
          }
        }}
        renderInput={(params: any) => {
          const newInputParams = {
            ...params.inputProps,
            value: valueToDisplay,
          };
          const newParams = { ...params, inputProps: newInputParams };
          return (
            <TextField
              {...newParams}
              value={valueToDisplay}
              error={leadValidationError.length > 0}
              placeholder={`Enter a name or email address`}
              id="team-lead-textfield"
              fullWidth
              margin="normal"
              variant="outlined"
              onKeyDown={(event) => handleKeyPress(event.key)}
            />
          );
        }}
      />
    </div>
  );
}
