/**
 *
 * AccountSettings
 *
 */

import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useSelector, useDispatch } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import { reducer, sliceKey, actions } from "./slice";
import { selectAccountSettings } from "./selectors";
import { selectUserAccountId } from "app/containers/Global/selectors";
import { accountSettingsSaga } from "./saga";
import "./styles.css";
import { SettingOption } from "./SettingOption";

interface Props {}

export function AccountSettings(props: Props) {
  useInjectReducer({
    key: sliceKey,
    reducer: reducer,
  });
  useInjectSaga({
    key: sliceKey,
    saga: accountSettingsSaga,
  });

  const dispatch = useDispatch();

  /****************************************************************************
   * Selectors
   ****************************************************************************/
  const accountSettings = useSelector(selectAccountSettings);
  const userAccountId = useSelector(selectUserAccountId);

  /****************************************************************************
   * Hooks
   ****************************************************************************/
  useEffect(() => {
    dispatch(actions.getUserSettings(userAccountId));
  }, [dispatch, userAccountId]);

  return (
    <>
      <Helmet>
        <title>Account Settings</title>
        <meta name="description" content="Account Settings" />
      </Helmet>
      <Container className="account-settings-container">
        <Row>
          <Col>
            <p className="header-text">Account Settings</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className="subheader-text mt-3">Privacy & Visibility</p>
          </Col>
        </Row>
        <Row>
          <Col lg={6} md={6} sm={12}>
            <hr className="separator" />
          </Col>
        </Row>
        <Row>
          <Col lg={6} md={6} sm={12}>
            <SettingOption
              text="Who can see your Collaboration Guide"
              value={accountSettings?.userSettings?.showPersonalitySummary}
              permissionToUpdate="showPersonalitySummary"
            />
          </Col>
        </Row>
        <Row>
          <Col lg={6} md={6} sm={12}>
            <SettingOption
              text="Who can see your Workplace Insights"
              value={accountSettings?.userSettings?.showWorkplaceInsights}
              permissionToUpdate="showWorkplaceInsights"
            />
          </Col>
        </Row>
        <Row>
          <Col lg={6} md={6} sm={12}>
            <SettingOption
              text="Who can see you on Team Reports"
              value={accountSettings?.userSettings?.showOnTeamReport}
              permissionToUpdate="showOnTeamReport"
            />
          </Col>
        </Row>
      </Container>
    </>
  );
}
