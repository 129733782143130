/**
 *
 * MyResults
 *
 */

import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useSelector, useDispatch } from "react-redux";
import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import { reducer, sliceKey, actions } from "./slice";
import {
  selectMyProfile,
  selectPendingTests,
  selectPersonalityProfile,
  selectUser,
  selectShowPersonalityProfile,
  selectPersonalityProfileLoaded,
  selectNoPermission,
  selectNoEPP,
  selectLoggedInUserPendingTests,
  selectCollaborationGuidePDF,
} from "./selectors";
import { selectUserAccountId } from "app/containers/Global/selectors";
import { myProfileSaga } from "./saga";
import { useParams, useLocation } from "react-router-dom";
import { HeadlinePanel } from "./HeadlinePanel";
import { Col, Container, Row } from "react-bootstrap";
import { Tabs, Tab } from "@material-ui/core";
import "./styles.css";
import TabPanel from "./TabPanel";
import { IndividualPersonality } from "../IndividualPersonality";
import { PendingTests, PersonalityProfile } from "./types";
import UploadPhotoModal from "./UploadPhotoModal";
import { CollaborationGuide } from "./CollaborationGuide";
import { SampleDataBanner } from "app/components/SampleDataBanner";

interface Props {}

export function MyProfile(props: Props) {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: myProfileSaga });

  const location = useLocation();

  // state variables
  const [showUploadPhotoModal, setShowUploadPhotoModal] = useState(false);

  const myProfile = useSelector(selectMyProfile);
  const { collaborationGuidePDF } = useSelector(selectCollaborationGuidePDF);
  const user: any = useSelector(selectUser);
  const pendingTests: PendingTests[] | null = useSelector(selectPendingTests);
  const personalityProfile: PersonalityProfile | null = useSelector(
    selectPersonalityProfile
  );
  const personalityProfileLoaded = useSelector(selectPersonalityProfileLoaded);
  const showPersonalityProfile: boolean = useSelector(
    selectShowPersonalityProfile
  );
  const noPermission: boolean = useSelector(selectNoPermission);
  const noEPP: boolean = useSelector(selectNoEPP);
  const loggedInUserPendingTests = useSelector(selectLoggedInUserPendingTests);
  /* get the logged in userAccountId for comparison (to take assessments, etc.) */
  const loggedInUserAccountId = useSelector(selectUserAccountId);

  const dispatch = useDispatch();
  const { userAccountId }: any = useParams();

  /****************************************************************************
   * State                                                                    *
   ****************************************************************************/
  const [value, setValue] = useState(
    userAccountId !== loggedInUserAccountId ? 0 : 1
  );

  /****************************************************************************
   * Hooks                                                                    *
   ****************************************************************************/
  useEffect(() => {
    dispatch(actions.getUser(userAccountId));
    // if the viewer is viewing their own profile, we only show the Workplace
    // Insights tab, so this API call is not needed.
    if (userAccountId !== loggedInUserAccountId) {
      dispatch(actions.getPersonalityProfile(userAccountId));
    } else {
      dispatch(actions.getPendingTests());
    }
    return () => {
      dispatch(actions.cleanUp());
    };
  }, [userAccountId, loggedInUserAccountId, dispatch]);

  // Download collaboration guide as a PDF
  // reference: https://medium.com/octopus-labs-london/downloading-a-base-64-pdf-from-an-api-request-in-javascript-6b4c603515eb
  useEffect(() => {
    if (collaborationGuidePDF) {
      const data = collaborationGuidePDF.data;
      const linkSource = `data:application/pdf;base64,${data[0]}`;
      const downloadLink = document.createElement("a");
      let joinedName = "";
      data.users.forEach((user) => {
        joinedName += user.firstName + " and ";
      });
      joinedName = joinedName.substr(0, joinedName.length - 5);
      const fileName = "Collaboration Guide - " + joinedName;

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
      downloadLink.remove();
    }
    return () => {
      dispatch(actions.resetcollaborationGuidePDF());
    };
  }, [collaborationGuidePDF, dispatch]);

  /****************************************************************************
   * Handlers                                                                 *
   ****************************************************************************/
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  /****************************************************************************
   * Upload Modal Handlers                                                    *
   ****************************************************************************/
  const handleUploadClick = () => {
    setShowUploadPhotoModal(true);
  };

  const uploadModalOnHide = () => {
    setShowUploadPhotoModal(false);
  };

  const handlePhotoUpload = (formData: any): void => {
    dispatch(actions.uploadImage(userAccountId, formData));
  };

  const handlePhotoDeletion = () => {
    dispatch(actions.deleteImage(userAccountId));
  };

  const downloadReport = () => {
    dispatch(actions.getCollaborationGuidePDF(userAccountId));
  };

  return (
    <div>
      <Helmet>
        <title>My Profile</title>
        <meta name="description" content="My Profile" />
      </Helmet>
      {user?.companyAccountId === 0 && <SampleDataBanner />}
      <div>
        <Container fluid="lg">
          <Row>
            <Col>
              <HeadlinePanel
                user={user}
                handleUploadClick={handleUploadClick}
                userAccountId={userAccountId}
                location={location}
              />
            </Col>
          </Row>
        </Container>
        <Container className="mt-3">
          {!pendingTests && myProfile.userLoaded ? (
            <>
              <Row>
                <Col>
                  {loggedInUserAccountId !== userAccountId ? (
                    <Tabs
                      value={value}
                      indicatorColor="primary"
                      onChange={handleChange}
                      aria-label="profile options"
                      className="tabs-override"
                    >
                      <Tab
                        label="Collaboration Guide"
                        disableRipple={true}
                        style={{ width: "200px" }}
                      />
                      <Tab
                        label="Workplace Insights"
                        disableRipple={true}
                        style={{ width: "200px" }}
                      />
                    </Tabs>
                  ) : null}
                  {/* Saving this in case we want to have it returned later:
                      <NotableTraitsContainer
                      notableTraits={notableTraits}
                      user={user}
                   /> */}
                </Col>
              </Row>
              <Row>
                <Col>
                  {loggedInUserAccountId !== userAccountId ? (
                    <>
                      <TabPanel value={value} index={0}>
                        <CollaborationGuide
                          personalityProfile={personalityProfile}
                          showPersonalityProfile={showPersonalityProfile}
                          noPermission={noPermission}
                          noEPP={noEPP}
                          personalityProfileLoaded={personalityProfileLoaded}
                          user={user}
                          downloadReport={downloadReport}
                          isSample={Boolean(user?.companyAccountId === 0)}
                        />
                      </TabPanel>
                      <TabPanel value={value} index={1}>
                        <IndividualPersonality
                          userAccountId={userAccountId}
                          loggedInUserPendingTests={loggedInUserPendingTests}
                        />
                      </TabPanel>
                    </>
                  ) : (
                    <IndividualPersonality
                      userAccountId={userAccountId}
                      loggedInUserPendingTests={loggedInUserPendingTests}
                    />
                  )}
                </Col>
              </Row>
            </>
          ) : null}
        </Container>
        <UploadPhotoModal
          show={showUploadPhotoModal}
          onHide={uploadModalOnHide}
          profilePicture={user?.profilePictureBase64}
          profilePictureFileType={user?.profilePictureFileType}
          profilePictureFileName={user?.profilePictureFileName}
          handlePhotoUpload={handlePhotoUpload}
          handlePhotoDeletion={handlePhotoDeletion}
        />
      </div>
    </div>
  );
}
