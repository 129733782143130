import { all, call, put, takeLatest } from "redux-saga/effects";
import { request } from "utils/request";
import { actions } from "./slice";
import { PendingTestsAndInvitationsData } from "./types";

export function* getUser(action: any) {
  try {
    const requestUrl = `${process.env.REACT_APP_API_URL}/teaming/users/${action.payload}`;
    const getUserResponse = yield call(request, requestUrl);
    yield put(actions.getUserSuccess(getUserResponse));
  } catch (error) {
    yield put(actions.getUserError(error.response));
  }
}

export function* getPersonalityProfile(action: any) {
  try {
    const requestUrl = `${process.env.REACT_APP_API_URL}/teaming/users/${action.payload}/personality`;
    const getPersonalityProfileResponse: any = yield call(request, requestUrl);

    yield put(
      actions.getPersonalityProfileSuccess(getPersonalityProfileResponse)
    );
  } catch (error) {
    yield put(actions.getPersonalityProfileError(error.response.status));
  }
}

export function* getPendingTests() {
  try {
    const requestUrl = `${process.env.REACT_APP_API_URL}/teaming/dashboard`;
    const teamsAndInvitationsResponse: PendingTestsAndInvitationsData = yield call(
      request,
      requestUrl
    );
    yield put(actions.getPendingTestsSuccess(teamsAndInvitationsResponse));
  } catch (error) {
    yield put(actions.getPendingTestsError(error.response));
  }
}

export function* uploadImage(action: any) {
  try {
    const requestUrl = `${process.env.REACT_APP_API_URL}/teaming/users/${action.payload.userAccountId}/profilePicture`;
    yield call(request, requestUrl, {
      method: "PUT",
      body: JSON.stringify(action.payload.formData),
    });
    yield put(actions.getUser(action.payload.userAccountId));
    yield put(actions.uploadImageSuccess());
  } catch (error) {
    yield put(actions.uploadImageError());
  }
}

export function* deleteImage(action: any) {
  try {
    const requestUrl = `${process.env.REACT_APP_API_URL}/teaming/users/${action.payload}/profilePicture`;
    yield call(request, requestUrl, {
      method: "DELETE",
    });
    yield put(actions.getUser(action.payload));
    yield put(actions.deleteImageSuccess());
  } catch (error) {
    yield put(actions.deleteImageError());
  }
}

export function* getCollaborationGuidePDF(action) {
  try {
    const requestUrl = `${process.env.REACT_APP_API_URL}/teaming/users/${action.payload}/collaboration`;
    const collaborationGuidePDFResponse = yield call(request, requestUrl);
    yield put(
      actions.getCollaborationGuidePDFSuccess(collaborationGuidePDFResponse)
    );
  } catch (error) {
    yield put(actions.getCollaborationGuidePDFError(error.response));
  }
}

export function* myProfileSaga() {
  yield all([
    takeLatest(actions.getUser.type, getUser),
    takeLatest(actions.getPersonalityProfile.type, getPersonalityProfile),
    takeLatest(actions.uploadImage.type, uploadImage),
    takeLatest(actions.deleteImage.type, deleteImage),
    takeLatest(actions.getPendingTests.type, getPendingTests),
    takeLatest(actions.getCollaborationGuidePDF.type, getCollaborationGuidePDF),
  ]);
}
