/**
 *
 * LinkButton
 *
 */
import React from "react";
import Skeleton from "react-loading-skeleton";
import rightArrow from "resources/images/arrow-right-blue.svg";
import leftArrow from "resources/images/arrow-left-blue.svg";
import disabledRightArrow from "resources/images/arrow-right-disabled.svg";
import chevronDown from "resources/images/chevron-down.svg";
import "./styles.css";

interface Props {
  text: string;
  showArrow: string;
  showChevron?: boolean;
  showUpChevron?: boolean;
  handler: () => void;
  isLoading?: boolean;
  style?: any;
  disabled?: boolean | null;
}

export function LinkButton({
  text,
  showArrow,
  showChevron,
  showUpChevron,
  handler,
  isLoading,
  style,
  disabled,
}: Props) {
  return (
    <>
      {isLoading ? (
        <div className="link-button" style={style}>
          <Skeleton width={150} duration={2} />
        </div>
      ) : (
        <button
          className="link-button"
          id="link-button"
          onClick={handler}
          style={{ ...style }}
          disabled={disabled ? true : false}
        >
          {showArrow === "left" ? (
            <img
              src={leftArrow}
              alt="left arrow"
              className="link-button-arrow mr-2"
            />
          ) : null}
          {text}
          {showArrow === "right" ? (
            disabled ? (
              <img
                src={disabledRightArrow}
                alt="right arrow"
                className="link-button-arrow"
              />
            ) : (
              <img
                src={rightArrow}
                alt="right arrow"
                className="link-button-arrow"
              />
            )
          ) : (
            ""
          )}
          {showChevron ? (
            <img
              src={chevronDown}
              alt="down arrow"
              className="link-button-arrow"
            />
          ) : (
            ""
          )}
          {showUpChevron ? (
            <img
              src={chevronDown}
              alt="up arrow"
              className="link-button-arrow"
              style={{ transform: "rotate(180deg)" }}
            />
          ) : (
            ""
          )}
        </button>
      )}
    </>
  );
}
