import { LinkButton } from "app/components/LinkButton";
import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { actions } from "./slice";

interface Props {
  showEditModal: boolean;
  selectedUser: any;
  setShowEditModal: (value: boolean) => void;
}

export default function EditUserModal({
  showEditModal,
  selectedUser,
  setShowEditModal,
}: Props) {
  const dispatch = useDispatch();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [accessRole, setAccessRole] = useState("1");

  useEffect(() => {
    setFirstName(selectedUser?.firstName);
    setLastName(selectedUser?.lastName);
    setJobTitle(selectedUser?.jobTitle);
    setEmailAddress(selectedUser?.emailAddress);
    setAccessRole(String(selectedUser?.tmgRoleId));
  }, [selectedUser]);

  const submitForm = (event: React.FormEvent<HTMLElement>) => {
    event.preventDefault();
    const formData = {
      firstName,
      lastName,
      jobTitle,
      emailAddress,
      tmgRoleId: accessRole,
      tmgSuspendedbyUserAccountId: 0,
    };
    dispatch(actions.editUser(selectedUser.userAccountId, formData));
    setShowEditModal(false);
  };

  return (
    <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
      <Form onSubmit={submitForm}>
        <Modal.Header closeButton className="modal-header">
          Edit User Profile
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col lg={6} md={6} sm={6}>
              <Form.Group controlId="firstName">
                <Form.Label className="form-label-text">First Name</Form.Label>
                <Form.Control
                  type="text"
                  value={firstName}
                  onChange={(event) => setFirstName(event.target.value)}
                />
              </Form.Group>
            </Col>
            <Col lg={6} md={6} sm={6}>
              <Form.Group controlId="accessRole">
                <Form.Label className="form-label-text">Access Role</Form.Label>
                <Form.Control
                  as="select"
                  value={accessRole}
                  onChange={(event) => setAccessRole(event.target.value)}
                >
                  <option value={"1"}>Admin</option>
                  <option value={"2"}>User</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={6} sm={6}>
              <Form.Group controlId="lastName">
                <Form.Label className="form-label-text">Last Name</Form.Label>
                <Form.Control
                  type="text"
                  value={lastName}
                  onChange={(event) => setLastName(event.target.value)}
                />
              </Form.Group>
            </Col>
            <Col lg={6} md={6} sm={6}>
              <Form.Group controlId="teamName">
                <Form.Label className="form-label-text">Job Title</Form.Label>
                <Form.Control
                  type="text"
                  value={jobTitle}
                  onChange={(event) => setJobTitle(event.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={6} sm={6}>
              <Form.Group controlId="teamName">
                <Form.Label className="form-label-text">
                  Email Address
                </Form.Label>
                <Form.Control
                  type="text"
                  value={emailAddress}
                  onChange={(event) => setEmailAddress(event.target.value)}
                  disabled={selectedUser?.setupAccount === 1}
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Col className="text-right">
            <LinkButton
              text="Cancel"
              showArrow=""
              handler={() => {}}
              isLoading={false}
              style={{
                fontFamily: "Lato",
                fontSize: "14px",
                lineHeight: "1.43",
                color: "#425cc7",
              }}
            />
            <button className="modal-button ml-5 pl-3 pr-3" type="submit">
              Save
            </button>
          </Col>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
