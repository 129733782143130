import React from "react";
import {
  PendingTestsData,
  TeamData,
  PendingInvitationsData,
} from "../Dashboard/types";
import { PendingTestBox } from "../Dashboard/HeadlinePanel/PendingTestBox";
import { PendingInvitationsBox } from "../Dashboard/HeadlinePanel/PendingInvitationsBox";
import { TeamBox } from "../Dashboard/Teams/TeamBox";
import { CollaborationUserRow } from "./CollaborationUserRow";
// import { User } from "./types";

export const getPendingTests = (
  pendingTests: PendingTestsData[] | null,
  isLoading?: boolean
) => {
  const testInvitationArray = pendingTests?.map((test) => {
    return (
      <PendingTestBox
        key={test.eventId}
        eventId={test.eventId}
        questionsAnswered={test.questionsAnswered}
        totalQuestions={test.totalQuestions}
        isLoading={isLoading}
      />
    );
  });
  return testInvitationArray;
};

export const getPendingInvitations = (
  pendingInvitations: PendingInvitationsData[] | null,
  acceptInvitation: (
    teamMemberId: number,
    teamId: number,
    teamName: string
  ) => void,
  isLoading?: boolean
) => {
  const pendingInvitationsArray = pendingInvitations?.map((invitation) => (
    <PendingInvitationsBox
      key={invitation.teamId}
      acceptInvitation={acceptInvitation}
      {...invitation}
    />
  ));
  return pendingInvitationsArray;
};

export const getTeamsArray = (
  teams: TeamData[] | null,
  handleViewTeam: (teadId: number) => void,
  showSampleTeams: boolean
) => {
  const sampleArr: any[] = [];
  const teamsArray = teams
    ? teams
        .slice()
        .sort((a, b) => a.teamName.trim().localeCompare(b.teamName.trim()))
        .map((team) => {
          if (team.isSample === 0) {
            return (
              <TeamBox
                key={team.teamId}
                team={team}
                handleViewTeam={handleViewTeam}
                teamId={team.teamId}
                sampleTeam={team.isSample !== 0}
              />
            );
          } else
            sampleArr.push(
              <TeamBox
                key={team.teamId}
                team={team}
                handleViewTeam={handleViewTeam}
                teamId={team.teamId}
                sampleTeam={team.isSample !== 0}
              />
            );
        })
    : null;
  if (teamsArray && showSampleTeams)
    sampleArr.forEach((elm) => {
      teamsArray.unshift(elm);
    });
  return teamsArray;
};

export const getCollaborationGuideUsersList = (collabUsers: User[] | null) => {
  const teamMembersList = collabUsers
    ?.slice()
    /* sort by first name, or, if one or both members are lacking that, email address */
    .sort((a: any, b: any) => {
      if (a.firstName && b.firstName) {
        return a.firstName
          .trim()
          .toLowerCase()
          .localeCompare(b.firstName.trim().toLowerCase());
      } else if (a.firstName && !b.firstName) {
        return a.firstName
          .trim()
          .toLowerCase()
          .localeCompare(b.emailAddress.trim().toLowerCase());
      } else if (!a.firstName && b.firstName) {
        return a.emailAddress
          .trim()
          .toLowerCase()
          .localeCompare(b.firstName.trim().toLowerCase());
      } else {
        return a.emailAddress
          .trim()
          .toLowerCase()
          .localeCompare(b.emailAddress.trim().toLowerCase());
      }
    })
    .map((member, index) => {
      return (
        <CollaborationUserRow
          key={member?.userAccountId}
          member={member}
          collaborationGuideUsers={collabUsers}
        />
      );
    });
  return teamMembersList;
};
