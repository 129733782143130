/**
 *
 * PendingActions
 *
 */
import React, { ReactNode } from "react";
import Skeleton from "react-loading-skeleton";
import { Col } from "react-bootstrap";
import {
  PendingInvitationsData,
  // PendingTestsData,
} from "app/containers/Dashboard/types";
import { getPendingInvitations } from "../helper-functions";

interface Props {
  // pendingTests: PendingTestsData[] | null;
  pendingInvitations: PendingInvitationsData[] | null;
  isLoading?: boolean;
  acceptInvitation: (
    teamMemberId: number,
    teamId: number,
    teamName: string
  ) => void;
}

export function PendingActions({
  // pendingTests,
  pendingInvitations,
  isLoading,
  acceptInvitation,
}: Props) {
  // create an array of the test invitations that the user has pending
  // const pendingTestsArray: ReactNode = pendingTests
  //   ? getPendingTests(pendingTests, isLoading)
  //   : [];
  const pendingInvitationsArray: ReactNode = getPendingInvitations(
    pendingInvitations,
    acceptInvitation,
    isLoading
  );

  /*
   * onScroll handler that removes the faded out affects when user has reached the bottom of the
   * element.  it reapplies it if they scroll up from there.
   */
  const handleScroll = (event: React.UIEvent<HTMLElement>) => {
    const target = event.currentTarget;
    if (target.scrollHeight - target.scrollTop === target.clientHeight) {
      const element = document.getElementById("pending-container");
      element?.classList.remove("fade-out");
    }
    if (!(target.scrollHeight - target.scrollTop === target.clientHeight)) {
      const element = document.getElementById("pending-container");
      element?.classList.add("fade-out");
    }
  };

  return (
    <Col
      lg={{ span: 6 }}
      md={{ span: 6 }}
      sm={{ span: 12 }}
      xs={12}
      className={`pending-actions-container ${
        // pendingTests &&
        pendingInvitations && pendingInvitations?.length > 2 ? "fade-out" : ""
      }`}
      onScroll={handleScroll}
      id="pending-container"
    >
      {isLoading ? (
        <>
          <div className="skeleton-pending-box">
            <Skeleton height={40} duration={2} />
          </div>
          <div className="skeleton-pending-box">
            <Skeleton height={40} duration={2} />
          </div>
        </>
      ) : (
        <>{pendingInvitationsArray}</>
      )}
    </Col>
  );
}
