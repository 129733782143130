import React from "react";
import { MemberRow } from "./MemberRow";
import { TeamMembers } from "./types";

export const getTeamMembersList = (
  teamMembers: TeamMembers[] | undefined,
  showRemoveMemberConfirmation: (teamMemberId: number | undefined) => void,
  isTeamLead: boolean,
  showResendInviteConfirmation,
  teamName: string | undefined,
  teamId: string
) => {
  const teamMembersList = teamMembers
    ?.slice()
    /* sort by first name, or, if one or both members are lacking that, email address */
    .sort((a: any, b: any) => {
      if (a.firstName && b.firstName) {
        return a.firstName
          .trim()
          .toLowerCase()
          .localeCompare(b.firstName.trim().toLowerCase());
      } else if (a.firstName && !b.firstName) {
        return a.firstName
          .trim()
          .toLowerCase()
          .localeCompare(b.emailAddress.trim().toLowerCase());
      } else if (!a.firstName && b.firstName) {
        return a.emailAddress
          .trim()
          .toLowerCase()
          .localeCompare(b.firstName.trim().toLowerCase());
      } else {
        return a.emailAddress
          .trim()
          .toLowerCase()
          .localeCompare(b.emailAddress.trim().toLowerCase());
      }
    })
    .sort((a: any, b: any) => (a.isTeamLead > b.isTeamLead ? -1 : 1))
    .map((member, index) => {
      const isLastMember = index === teamMembers.length - 1;
      return (
        <MemberRow
          key={member.teamMemberId}
          member={member}
          isLastMember={isLastMember}
          showRemoveMemberConfirmation={showRemoveMemberConfirmation}
          isTeamLead={isTeamLead}
          showResendInviteConfirmation={showResendInviteConfirmation}
          teamName={teamName}
          teamId={teamId}
        />
      );
    });
  return teamMembersList;
};
