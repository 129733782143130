import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "utils/@reduxjs/toolkit";
import { ContainerState } from "./types";

// The initial state of the Dashboard container
export const initialState: ContainerState = {
  user: null,
  teams: null,
  companyInfo: null,
  collaborationGuideUsers: null,
  pendingTests: null,
  pendingInvitations: null,
  notifications: null,
  loadingUser: false,
  loadingTeams: false,
  loadingCompanyInfo: false,
  loadingCollaborationGuideUsers: false,
  loadingTestsAndInvitations: false,
  loadingNotifications: false,
  acceptingInvitation: false,
  errorMessage: "",
  showInvitationAcceptedModal: false,
  invitationAcceptedTeamName: null,
  invitationAcceptedTeamId: null,
  hasSSOHardEnabled: true,
  loadingTeam: false,
  loadingUserSettings: false,
  userSettings: null,
  savingSetting: false,
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    getUser(state, action: PayloadAction<any>) {
      state.loadingUser = true;
    },
    getUserSuccess(state, action: PayloadAction<any>) {
      state.loadingUser = false;
      state.user = action.payload;
    },
    getUserError(state, action: PayloadAction<any>) {
      state.loadingUser = false;
    },
    setUser(state, action: PayloadAction<any>) {
      state.user = action.payload;
    },
    getTeams(state, action: PayloadAction<any>) {
      state.loadingTeams = true;
    },
    getTeamsSuccess(state, action: PayloadAction<any>) {
      state.loadingTeams = false;
      state.teams = action.payload;
    },
    getTeamsError(state, action: PayloadAction<any>) {
      state.loadingTeams = false;
      state.errorMessage =
        "There was a problem returning your company information.";
    },
    getCompanyInfo(state, action: PayloadAction<any>) {
      state.loadingCompanyInfo = true;
    },
    getCompanyInfoSuccess(state, action: PayloadAction<any>) {
      state.loadingCompanyInfo = false;
      state.companyInfo = action.payload;
      state.hasSSOHardEnabled = action.payload.identityProviderMode === 2;
    },
    getCompanyInfoError(state, action: PayloadAction<any>) {
      state.loadingCompanyInfo = false;
      state.errorMessage = action.payload.message;
    },
    getCollaborationGuideUsers(state) {
      state.loadingCollaborationGuideUsers = true;
    },
    getCollaborationGuideUsersSuccess(state, action: PayloadAction<any>) {
      state.loadingCollaborationGuideUsers = false;
      state.collaborationGuideUsers = action.payload;
    },
    getCollaborationGuideUsersError(state, action: PayloadAction<any>) {
      state.loadingCollaborationGuideUsers = false;
      state.errorMessage = action.payload.message;
    },
    getTeamsAndInvitations(state) {
      state.loadingTestsAndInvitations = true;
    },
    getTeamsAndInvitationsSuccess(state, action: PayloadAction<any>) {
      state.loadingTestsAndInvitations = false;
      state.pendingTests = action?.payload.pendingTests;
      state.pendingInvitations = action?.payload.pendingTeamInvitations;
    },
    getTeamsAndInvitationsError(state, action: PayloadAction<any>) {
      state.loadingTestsAndInvitations = false;
      state.errorMessage = action.payload.message;
    },
    getNotifications(state) {
      state.loadingNotifications = true;
    },
    getNotificationsSuccess(state, action: PayloadAction<any>) {
      state.loadingNotifications = false;
      state.notifications = action.payload;
    },
    getNotificationsError(state, action: PayloadAction<any>) {
      state.loadingNotifications = false;
      state.errorMessage = action.payload.message;
    },
    getUserSettings(state, action: PayloadAction<any>) {
      state.loadingUserSettings = true;
    },
    getUserSettingsSuccess(state, action: PayloadAction<any>) {
      state.loadingUserSettings = false;
      state.userSettings = { ...action.payload };
    },
    getUserSettingsError(state) {
      state.loadingUserSettings = false;
    },
    saveSetting: {
      reducer(state, action: PayloadAction<any>) {
        state.savingSetting = true;
      },
      prepare(userAccountId, formData) {
        return {
          payload: {
            userAccountId: userAccountId,
            formData: formData,
          },
        };
      },
    },
    saveSettingSuccess(state, action: PayloadAction<any>) {
      state.savingSetting = false;
    },
    saveSettingError(state) {
      state.savingSetting = false;
    },
    acceptInvitation(state, action: PayloadAction<any>) {
      state.acceptingInvitation = true;
      state.invitationAcceptedTeamId = action.payload.teamId;
      state.invitationAcceptedTeamName = action.payload.teamName;
    },
    acceptInvitationSuccess(state) {
      state.acceptingInvitation = false;
      state.showInvitationAcceptedModal = true;
    },
    acceptInvitationError(state, action: PayloadAction<any>) {
      state.acceptingInvitation = false;
      state.errorMessage = action.payload;
    },
    resetAcceptInvitationModalData(state) {
      state.showInvitationAcceptedModal =
        initialState.showInvitationAcceptedModal;
      state.invitationAcceptedTeamName =
        initialState.invitationAcceptedTeamName;
      state.invitationAcceptedTeamId = initialState.invitationAcceptedTeamId;
    },
  },
});

export const { actions, reducer, name: sliceKey } = dashboardSlice;
