/**
 *
 * HeadlinePanel
 *
 */
import React from "react";
import { Container, Row } from "react-bootstrap";
import { PendingTestsData, PendingInvitationsData } from "../types";
import { UserWelcome } from "./UserWelcome";
import { PendingActions } from "./PendingActions";

export interface Props {
  user: User | null;
  pendingTests: PendingTestsData[] | null;
  pendingInvitations: PendingInvitationsData[] | null;
  isLoading: boolean;
  acceptInvitation: (
    teamMemberId: number,
    teamId: number,
    teamName: string
  ) => void;
  setShowInviteLinkModal: any;
  hasSSOHardEnabled: boolean;
  allowUsersShareInviteLink: number | undefined;
}

export function HeadlinePanel({
  user,
  pendingTests,
  pendingInvitations,
  isLoading,
  acceptInvitation,
  setShowInviteLinkModal,
  hasSSOHardEnabled,
  allowUsersShareInviteLink,
}: Props) {
  return (
    <div className="headline-panel">
      <Container>
        <Row>
          <UserWelcome
            user={user}
            numberOfActions={
              pendingTests && pendingInvitations
                ? pendingTests.length + pendingInvitations.length
                : 0
            }
            isLoading={isLoading}
            setShowInviteLinkModal={setShowInviteLinkModal}
            hasSSOHardEnabled={hasSSOHardEnabled}
            allowUsersShareInviteLink={allowUsersShareInviteLink}
          />
          <PendingActions
            // pendingTests={pendingTests}
            pendingInvitations={pendingInvitations}
            isLoading={isLoading}
            acceptInvitation={acceptInvitation}
          />
        </Row>
      </Container>
    </div>
  );
}
