import { call, put, all, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
import { request } from "utils/request";
import { TeamData, PendingTestsAndInvitationsData } from "./types";

export function* getUser(action) {
  try {
    const requestUrl = `${process.env.REACT_APP_API_URL}/teaming/users/${action.payload}`;
    const getUserResponse = yield call(request, requestUrl);
    yield put(actions.getUserSuccess(getUserResponse));
  } catch (error) {
    yield put(
      actions.getUserError("There was a problem retrieving your data.")
    );
  }
}

export function* getUserSettings(action) {
  try {
    const requestUrl = `${process.env.REACT_APP_API_URL}/teaming/users/${action.payload}/settings`;
    const getUserSettingsResponse = yield call(request, requestUrl);
    yield put(actions.getUserSettingsSuccess(getUserSettingsResponse));
  } catch (error) {
    yield put(actions.getUserSettingsError());
  }
}

export function* saveSetting(action) {
  try {
    const requestUrl = `${process.env.REACT_APP_API_URL}/teaming/users/${action.payload.userAccountId}/settings`;
    const saveSettingResponse = yield call(request, requestUrl, {
      method: "PUT",
      body: JSON.stringify(action.payload.formData),
    });
    yield put(actions.saveSettingSuccess(saveSettingResponse));
  } catch (error) {
    yield put(actions.saveSettingError());
  }
}

export function* getTeams(action) {
  try {
    const requestUrl = `${process.env.REACT_APP_API_URL}/teaming/users/${action.payload}/teams`;
    const getTeamsResponse: TeamData[] = yield call(request, requestUrl);
    yield put(actions.getTeamsSuccess(getTeamsResponse));
  } catch (error) {
    yield put(actions.getTeamsError(error.response));
  }
}

export function* getCompanyInfo(action) {
  try {
    const requestUrl = `${process.env.REACT_APP_API_URL}/teaming/company/${action.payload}`;
    const getCompanyInfoResponse = yield call(request, requestUrl);
    yield put(actions.getCompanyInfoSuccess(getCompanyInfoResponse));
  } catch (error) {
    yield put(actions.getCompanyInfoError(error.response));
  }
}

export function* getCollaborationGuideUsers() {
  try {
    const requestUrl = `${process.env.REACT_APP_API_URL}/teaming/collaborationGuideUsers`;
    const getCollaborationGuideUsersResponse = yield call(request, requestUrl);
    yield put(
      actions.getCollaborationGuideUsersSuccess(
        getCollaborationGuideUsersResponse
      )
    );
  } catch (error) {
    yield put(actions.getCollaborationGuideUsersError(error.response));
  }
}

export function* getTestsAndInvitations() {
  try {
    const requestUrl = `${process.env.REACT_APP_API_URL}/teaming/dashboard`;
    const teamsAndInvitationsResponse: PendingTestsAndInvitationsData = yield call(
      request,
      requestUrl
    );
    yield put(
      actions.getTeamsAndInvitationsSuccess(teamsAndInvitationsResponse)
    );
  } catch (error) {
    yield put(actions.getTeamsAndInvitationsError(error.response));
  }
}

export function* getNotifications() {
  try {
    const requestUrl = `${process.env.REACT_APP_API_URL}/teaming/notifications`;
    const notificationsResponse: PendingTestsAndInvitationsData = yield call(
      request,
      requestUrl
    );
    yield put(actions.getNotificationsSuccess(notificationsResponse));
  } catch (error) {
    yield put(actions.getNotificationsError(error.response));
  }
}

export function* acceptInvitation(action) {
  try {
    const requestUrl = `${process.env.REACT_APP_API_URL}/teaming/teamMembers/${action.payload.teamMemberId}/accept`;
    yield call(request, requestUrl, {
      method: "POST",
    });
    yield put(actions.getTeams(action.payload.userAccountId));
    yield put(actions.getTeamsAndInvitations());
    yield put(actions.acceptInvitationSuccess());
  } catch (error) {
    yield put(actions.acceptInvitationError(error.response));
  }
}

export function* dashboardSaga() {
  yield all([
    takeLatest(actions.getUser.type, getUser),
    takeLatest(actions.getTeams.type, getTeams),
    takeLatest(actions.getCompanyInfo.type, getCompanyInfo),
    takeLatest(actions.getTeamsAndInvitations.type, getTestsAndInvitations),
    takeLatest(actions.getNotifications.type, getNotifications),
    takeLatest(actions.acceptInvitation.type, acceptInvitation),
    takeLatest(
      actions.getCollaborationGuideUsers.type,
      getCollaborationGuideUsers
    ),
    takeLatest(actions.getUserSettings.type, getUserSettings),
    takeLatest(actions.saveSetting.type, saveSetting),
  ]);
}
