import React from "react";
import { Col, Row } from "react-bootstrap";
import Switch from "react-switch";

interface Props {
  handleAllowUsersToCreateTeams: (checked: boolean) => void;
  handleAllowUsersShareInviteLink: (checked: boolean) => void;
  handleAllowLinkResults: (checked: boolean) => void;
  tmgAllowUsersCreateTeam: number;
  allowUsersShareInviteLink: number;
  allowLinkResults: number;
  hasSSOHardEnabled: boolean;
}

export default function CompanySettings({
  handleAllowUsersToCreateTeams,
  handleAllowUsersShareInviteLink,
  handleAllowLinkResults,
  tmgAllowUsersCreateTeam,
  allowUsersShareInviteLink,
  allowLinkResults,
  hasSSOHardEnabled,
}: Props) {
  return (
    <div>
      <span className="table-header">GENERAL</span>
      <hr className="separator" />
      <span className="edit-form-label">Allow users to create teams</span>
      <br />
      <Row className="d-flex m-2">
        <Col lg={6} md={6} sm={6} xs={9}>
          <span className="setting-desc">
            Allow any user in your organization to create teams and invite team
            members to Criteria.
          </span>
        </Col>
        <Col lg={2} md={2} sm={2} xs={3}>
          <Switch
            onChange={handleAllowUsersToCreateTeams}
            checked={tmgAllowUsersCreateTeam === 1}
            className="react-switch"
            uncheckedIcon={false}
            checkedIcon={false}
            onColor={"#2dccd3"}
            height={18}
            width={40}
          />
        </Col>
      </Row>
      <hr className="separator" />
      <span className="edit-form-label">
        Allow users to invite users using the invite link
      </span>
      <br />
      <Row className="d-flex m-2">
        <Col lg={6} md={6} sm={6} xs={9}>
          <span className="setting-desc">
            Allow any user in your organization to invite new users using a
            company invite link.
          </span>
        </Col>
        <Col lg={2} md={2} sm={2} xs={3}>
          <Switch
            onChange={handleAllowUsersShareInviteLink}
            checked={allowUsersShareInviteLink === 1}
            className="react-switch"
            uncheckedIcon={false}
            checkedIcon={false}
            onColor={"#2dccd3"}
            height={18}
            width={40}
            disabled={hasSSOHardEnabled}
          />
        </Col>
      </Row>
      <hr className="separator" />
      <span className="edit-form-label">
        Allow users to link previous results
      </span>
      <br />
      <Row className="d-flex m-2">
        <Col lg={6} md={6} sm={6} xs={9}>
          <span className="setting-desc">
            Allow users to link results that they’ve previously completed with
            your organization to their employee profile. This can save time if a
            user previously took a test as a candidate for a position at your
            company.
          </span>
        </Col>
        <Col lg={2} md={2} sm={2} xs={3}>
          <Switch
            onChange={handleAllowLinkResults}
            checked={allowLinkResults === 1}
            className="react-switch"
            uncheckedIcon={false}
            checkedIcon={false}
            onColor={"#2dccd3"}
            height={18}
            width={40}
            disabled={hasSSOHardEnabled}
          />
        </Col>
      </Row>
    </div>
  );
}
