/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { GlobalStyle } from "styles/global-styles";
import { NotFoundPage } from "./components/NotFoundPage/Loadable";
import useEffectOnMount from "utils/custom_hooks/useEffectOnMount";
import { useCookies } from "react-cookie";
import { Dashboard } from "./containers/Dashboard";
import { GlobalNavBar } from "./components/GlobalNavBar";
import { CreateTeam } from "./containers/CreateTeam";
import { TeamPage } from "./containers/TeamPage";
import { TeamPersonality } from "./containers/TeamPersonality";
import { MyProfile } from "./containers/MyProfile";
import { InviteConfirmation } from "./containers/InviteConfirmation";
import { AccountSetup } from "./containers/AccountSetup";
import { TeamList } from "./containers/TeamList";
import { TeamSettings } from "./containers/TeamSettings";
import { AdminConsole } from "./containers/AdminConsole";
import { InviteLink } from "./containers/InviteLink";
import queryString from "query-string";
import { AccountSettings } from "./containers/AccountSettings";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "types";
import { selectUser } from "./containers/Global/selectors";
import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import { reducer, sliceKey, actions } from "./containers/Global/slice";
import { globalSaga } from "./containers/Global/saga";

declare global {
  interface Window {
    ChurnZero: any;
    analytics: any;
  }

  interface User {
    userAccountId: number;
    firstName: string;
    lastName: string;
    emailAddress: string;
    profilePicture: string;
    avatarColor: string;
    hireselectAccess?: boolean;
    companyAccountId?: number;
    tmgRoleId?: number;
    initials?: string;
    userName?: string;
    expired?: boolean;
    rep?: {
      emailAddress: string;
    };
  }

  export interface CompanyInfo {
    tmgAllowUsersCreateTeam: number;
    allowedDomains: string[];
    companyName: string;
    allowUsersShareInviteLink: number;
    inviteLink: string;
    identityProviderMode: number;
  }

  export interface DecodedJWT {
    iss: string;
    jti: number;
    iat: number;
    nbf: number;
    exp: number;
    sub: number; // userAccountId
    scopes: string[];
  }
}

export function App() {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: globalSaga });
  // when the application mounts, we want to check whether a crit token has been
  // added to cookies.  if so, this means they are successfully authenticated. if
  // not, they are redirected to the login center.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies(["name"]);
  const [token, setToken] = useState<string | null>(null);
  const dispatch = useDispatch();

  const user = useSelector(selectUser);

  const company: CompanyInfo | null = useSelector((state: RootState) => {
    return state?.dashboard?.companyInfo || null;
  });

  useEffectOnMount(() => {
    if (cookies.critToken) {
      localStorage.setItem("tmg-tkn", cookies.critToken);
      removeCookie("rdt-ti", {
        path: "/",
        domain: ".criteriacorp.com",
      });
      removeCookie("critToken", {
        path: "/",
        domain: ".criteriacorp.com",
      });
      setToken(localStorage.getItem("tmg-tkn"));
    } else if (
      !localStorage.getItem("tmg-tkn") &&
      window.location.pathname !== "/invites" &&
      window.location.pathname !== "/accountSetup" &&
      !window.location.pathname.includes("/join")
    ) {
      if (
        queryString.parse(window.location.search).team &&
        queryString.parse(window.location.search).tid
      ) {
        // if the query params contain team and tid, then that means the user has
        // accepted a team invitation
        const teamName = String(queryString.parse(window.location.search).team);
        const teamId = String(queryString.parse(window.location.search).tid);
        localStorage.setItem("teamName", teamName);
        localStorage.setItem("tid", teamId);
      }
      setCookie("rdt-ti", 1, {
        path: "/",
        domain: ".criteriacorp.com",
      });

      window.location.href = `${process.env.REACT_APP_LOGOUT_URL}`;
    }
  });

  useEffect(() => {
    if (!token) setToken(localStorage.getItem("tmg-tkn"));

    if (!user && token) {
      const decodedToken: DecodedJWT = jwtDecode(token);
      dispatch(actions.getUser(decodedToken.sub));
    }

    if (user && company) {
      let CZ = window.ChurnZero;

      CZ.push(["setAppKey", `${process.env.REACT_APP_CHURNZERO_KEY}`]);
      CZ.push([
        "setContact",
        `${user.companyAccountId}`,
        `${user.emailAddress}`,
      ]);
      CZ.push([
        "setAttribute",
        "account",
        {
          Name: company.companyName,
          OwnerUserAccount: user.rep?.emailAddress,
          IsActive: !user.expired,
        },
      ]);
      CZ.push([
        "setAttribute",
        "contact",
        {
          FirstName: user.firstName,
          LastName: user.lastName,
          Email: user.emailAddress,
        },
      ]);
    }
  }, [dispatch, company, token, user]);

  // if the token no longer exists in local storage, then the user has
  // been logged out, so they are redirected to the Login center.
  return (
    <BrowserRouter>
      <Helmet titleTemplate="%s - Talent Insights" defaultTitle="Criteria Corp">
        <meta name="description" content="A Criteria Corp application" />
      </Helmet>
      <GlobalNavBar />
      <Switch>
        <Route path="/accountSetup" component={AccountSetup} />
        <Route path="/invites" component={InviteConfirmation} />
        <Route path="/join/:code" component={InviteLink} />
        <PrivateRoute path="/team_list/:teamId">
          <TeamList />
        </PrivateRoute>
        <PrivateRoute path="/create">
          <CreateTeam />
        </PrivateRoute>
        <PrivateRoute path="/team/:teamId">
          <TeamPage />
        </PrivateRoute>
        <PrivateRoute path="/team_personality/:teamId">
          <TeamPersonality />
        </PrivateRoute>
        <PrivateRoute path="/profile/:userAccountId?">
          <MyProfile />
        </PrivateRoute>
        <PrivateRoute path="/team_settings/:teamId">
          <TeamSettings />
        </PrivateRoute>
        <PrivateRoute path="/account_settings">
          <AccountSettings />
        </PrivateRoute>
        <PrivateRoute path="/admin">
          <AdminConsole />
        </PrivateRoute>
        {token ? (
          <PrivateRoute path="/">
            <Dashboard />
          </PrivateRoute>
        ) : null}
        <Route path="/notfound" component={NotFoundPage} />
      </Switch>
      <GlobalStyle />
    </BrowserRouter>
  );
}

/******************************************************************************
 * Private Router Wrapper                                                     *
 ******************************************************************************/

// A wrapper for <Route> that redirects to the login  screen if you're not yet
// authenticated.
function PrivateRoute({ children, ...rest }) {
  const [cookies, setCookie] = useCookies(["name"]);
  const token: string | null =
    localStorage.getItem("tmg-tkn") || cookies.critToken;

  let decodedToken: any = null;
  if (token) {
    decodedToken = jwtDecode(token);
  }

  // user is authenticated if there is a valid token that has not expired yet
  // saved in localStorage.
  const authenticated = decodedToken && Date.now() / 1000 <= decodedToken.exp;
  if (!authenticated) {
    console.log("here");
    // check if the route contains the my_summary path, and if so, set the
    // approprite cookie to enable deep linking through the hireselect login.
    if (window.location.pathname.includes("profile")) {
      const userAccountId = /[^/]*$/.exec(window.location.pathname)?.[0];
      setCookie("rdt-sum", 1, {
        path: "/",
        domain: ".criteriacorp.com",
      });
      setCookie("rdt-uid", userAccountId, {
        path: "/",
        domain: ".criteriacorp.com",
      });
    }
    if (window.location.pathname.includes("team")) {
      const teamId = /[^/]*$/.exec(window.location.pathname)?.[0];
      setCookie("rdt-team", 1, {
        path: "/",
        domain: ".criteriacorp.com",
      });
      setCookie("rdt-tid", teamId, {
        path: "/",
        domain: ".criteriacorp.com",
      });
    }
    window.location.href = `${process.env.REACT_APP_LOGOUT_URL}`;
  }
  return <Route {...rest} render={({ location }) => children} />;
}
