import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "types";
import { initialState } from "./slice";

const selectDomain = (state: RootState) => state.dashboard || initialState;

export const selectDashboard = createSelector(
  [selectDomain],
  (dashboardState) => dashboardState
);

export const selectUser = createSelector(
  [selectDomain],
  (dashboardState) => dashboardState.user
);

export const selectTeams = createSelector(
  [selectDomain],
  (dashboardState) => dashboardState.teams
);

export const selectPendingTests = createSelector(
  [selectDomain],
  (dashboardState) => dashboardState.pendingTests
);

export const selectPendingInvitations = createSelector(
  [selectDomain],
  (dashboardState) => dashboardState.pendingInvitations
);

export const selectCompanyInfo = createSelector(
  [selectDomain],
  (dashboardState) => dashboardState.companyInfo
);

export const selectCollaborationGuideUsers = createSelector(
  [selectDomain],
  (dashboardState) => dashboardState.collaborationGuideUsers
);

export const selectDashboardLoading = createSelector(
  [selectDomain],
  (dashboardState) => {
    return (
      dashboardState.loadingTeams ||
      dashboardState.loadingNotifications ||
      dashboardState.loadingTestsAndInvitations ||
      dashboardState.loadingCompanyInfo ||
      dashboardState.loadingCollaborationGuideUsers
    );
  }
);

export const selectInvitationAcceptedData = createSelector(
  [selectDomain],
  (dashboardState) => {
    return {
      showInvitationAcceptedModal: dashboardState.showInvitationAcceptedModal,
      invitationAcceptedTeamName: dashboardState.invitationAcceptedTeamName,
      invitationAcceptedTeamId: dashboardState.invitationAcceptedTeamId,
    };
  }
);

export const selectHasSSOHardEnabled = createSelector(
  [selectDomain],
  (dashboardState) => dashboardState.hasSSOHardEnabled
);

export const selectAccountSettings = createSelector(
  [selectDomain],
  (dashboardState) => {
    return {
      loadingUserSettings: dashboardState.loadingUserSettings,
      userSettings: dashboardState.userSettings,
      savingSetting: dashboardState.savingSetting,
    };
  }
);
