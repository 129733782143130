import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "types";
import { initialState } from "./slice";

const selectDomain = (state: RootState) => state.accountSetup || initialState;

export const selectAccountSetup = createSelector(
  [selectDomain],
  (accountSetupState) => accountSetupState
);

export const selectInvitationAccepted = createSelector(
  [selectDomain],
  (accountSetupState) => accountSetupState.invitationAccepted
);

export const selectExpiredLink = createSelector(
  [selectDomain],
  (accountSetupState) => accountSetupState.expiredLink
);

export const selectAlreadyAccepted = createSelector(
  [selectDomain],
  (accountSetupState) => accountSetupState.alreadyAccepted
);
