import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dropdown } from "react-bootstrap";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import { actions } from "../slice";
import { selectInviteSentSuccessfully, selectUsers } from "../selectors";
import {
  getComparator,
  getUsersByStatus,
  stableSort,
} from "../helper-functions";
import menu from "resources/images/menu.svg";
import { useHistory } from "react-router-dom";
import { ConfirmationModal } from "app/components/ConfirmationModal";
import EditUserModal from "../EditUserModal";

interface Props {
  searchValue: string;
  viewUserStatus: string;
}

interface Data {
  firstName: string;
  lastName: string;
  emailAddress: string;
  jobTitle: string;
  accessRole: number;
  numberOfTeams: number;
  teamMemberId: string;
  setupAccount: number;
}

interface HeadCell {
  id: keyof Data;
  label: string;
}
const headCells: HeadCell[] = [
  { id: "firstName", label: "First Name" },
  { id: "lastName", label: "Last Name" },
  { id: "jobTitle", label: "Job Title" },
  { id: "emailAddress", label: "Email Address" },
  { id: "accessRole", label: "Access Role" },
  { id: "numberOfTeams", label: "# of Teams" },
];

function createData(
  firstName: string,
  lastName: string,
  jobTitle: string,
  emailAddress: string,
  accessRole: number,
  numberOfTeams: number,
  teamMemberId: string,
  setupAccount: number
): Data {
  return {
    firstName,
    lastName,
    jobTitle,
    emailAddress,
    accessRole,
    numberOfTeams,
    teamMemberId,
    setupAccount,
  };
}

type Order = "asc" | "desc";

const UsersTable = ({ searchValue, viewUserStatus }: Props) => {
  // state variables
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof Data>("firstName");
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [page, setPage] = useState(0);
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [showChangeStatusModal, setShowChangeStatusModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const users = useSelector(selectUsers);
  const inviteSentSuccessfully = useSelector(selectInviteSentSuccessfully);

  const usersToShow = getUsersByStatus(viewUserStatus, users);

  useEffect(() => {
    if (!users) {
      dispatch(actions.getUsers());
    }
  }, [users, dispatch]);

  useEffect(() => {
    if (!showChangeStatusModal) setSelectedUser(null);
  }, [showChangeStatusModal]);

  useEffect(() => {
    setPage(0);
  }, [viewUserStatus]);

  // create the array of rows to be displayed in the table
  let rows;
  if (usersToShow) {
    rows = Object.keys(usersToShow).map((teamMemberId) => {
      return createData(
        usersToShow[teamMemberId].firstName,
        usersToShow[teamMemberId].lastName,
        usersToShow[teamMemberId].jobTitle,
        usersToShow[teamMemberId].emailAddress,
        usersToShow[teamMemberId].tmgRoleId,
        usersToShow[teamMemberId].teams,
        usersToShow[teamMemberId].userAccountId,
        usersToShow[teamMemberId].setupAccount
      );
    });
  }

  const filteredRows = rows?.filter((row) => {
    if (
      String(row.firstName).toLowerCase().includes(searchValue.toLowerCase()) ||
      String(row.lastName).toLowerCase().includes(searchValue.toLowerCase()) ||
      String(row.emailAddress).toLowerCase().includes(searchValue.toLowerCase())
    )
      return true;
    return false;
  });

  /****************************************************************************
   * Sorting and Pagination Functions                                                        *
   ****************************************************************************/
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const createSortHandler = (property: keyof Data) => (
    event: React.MouseEvent<unknown>
  ) => {
    handleRequestSort(event, property);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  // Dropdown Handler
  const handleDropdown = (event, teamMemberId) => {
    switch (event) {
      case "invite":
        const userToInvite = usersToShow?.filter(
          (currentUser) => currentUser.userAccountId === teamMemberId
        );
        if (userToInvite) {
          setSelectedUser(userToInvite[0]);
          dispatch(actions.resendInvites(teamMemberId));
        }
        break;
      case "view":
        history.push(`/profile/${teamMemberId}`);
        break;
      case "deactivate":
        const userToDelete = usersToShow?.filter(
          (currentUser) => currentUser.userAccountId === teamMemberId
        );
        if (userToDelete) {
          setSelectedUser(userToDelete[0]);
          setShowChangeStatusModal(true);
        }
        break;
      case "reactivate":
        const formData = {
          tmgSuspendedByUserAccountId: 0,
        };
        dispatch(actions.editUser(teamMemberId, formData));
        break;
      case "edit":
        const userToEdit = usersToShow?.filter(
          (currentUser) => currentUser.userAccountId === teamMemberId
        );
        if (userToEdit) {
          setSelectedUser(userToEdit[0]);
          setShowEditModal(true);
        }
        break;
      default:
        return false;
    }
  };

  const handleDeactivateUser = () => {
    if (selectedUser) {
      dispatch(actions.deactivateUser(selectedUser.userAccountId));
      setShowChangeStatusModal(false);
    }
  };

  const resetInvitesSentSuccessfully = () => {
    dispatch(actions.resetInvitesSentSuccessfully());
  };

  return (
    <>
      <TableContainer
        style={{
          maxHeight: "70vh",
          minWidth: "768px",
          maxWidth: "1268px",
          overflow: "auto",
        }}
      >
        <Table
          aria-labelledby="Teams Table"
          size="medium"
          aria-label="teams table"
          stickyHeader={true}
        >
          <colgroup>
            <col style={{ width: "15%" }} />
            <col style={{ width: "15%" }} />
            <col style={{ width: "15%" }} />
            <col style={{ width: "28%" }} />
            <col style={{ width: "15%" }} />
            <col style={{ width: "17%" }} />
            <col style={{ width: "5%" }} />
          </colgroup>
          <TableHead className="table-header">
            <TableRow>
              {headCells.map((headCell) => (
                <TableCell key={headCell.id} align="left">
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : "asc"}
                    onClick={createSortHandler(headCell.id)}
                  >
                    {headCell.label}
                  </TableSortLabel>
                </TableCell>
              ))}
              <TableCell key={"teamId"} align="left"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              ? stableSort(filteredRows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow key={index}>
                      <TableCell
                        component="th"
                        scope="row"
                        align="left"
                        className={`link-as-text ${
                          !row.setupAccount ? "table-text" : "muted-text"
                        }`}
                      >
                        {!row.setupAccount ? (
                          <a href={`/profile/${row.teamMemberId}`}>
                            {row.firstName}
                          </a>
                        ) : (
                          row.firstName
                        )}
                      </TableCell>
                      <TableCell
                        align="left"
                        className={`link-as-text
                        ${!row.setupAccount ? "table-text" : "muted-text"}
                      `}
                      >
                        {!row.setupAccount ? (
                          <a href={`/profile/${row.teamMemberId}`}>
                            {row.lastName}
                          </a>
                        ) : (
                          row.lastName
                        )}
                      </TableCell>
                      <TableCell
                        align="left"
                        className={
                          !row.setupAccount ? "table-text" : "muted-text"
                        }
                      >
                        {row.jobTitle}
                      </TableCell>
                      <TableCell
                        align="left"
                        className={
                          !row.setupAccount ? "table-text" : "muted-text"
                        }
                      >
                        {row.emailAddress}
                      </TableCell>
                      <TableCell
                        align="left"
                        className={
                          !row.setupAccount ? "table-text" : "muted-text"
                        }
                      >
                        {row.accessRole === 1 ? "Admin" : "User"}
                      </TableCell>
                      <TableCell
                        align="left"
                        className={
                          !row.setupAccount ? "table-text" : "muted-text"
                        }
                      >
                        {row.numberOfTeams}
                      </TableCell>
                      <TableCell align="right">
                        <Dropdown
                          onSelect={(event) =>
                            handleDropdown(event, row.teamMemberId)
                          }
                        >
                          <div className="no-caret">
                            <Dropdown.Toggle size="sm" className="menu-button">
                              <img
                                src={menu}
                                alt="menu"
                                width="24"
                                height="24"
                              />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {!row.setupAccount ? (
                                <>
                                  <Dropdown.Item
                                    eventKey="edit"
                                    data-id={row.teamMemberId}
                                  >
                                    Edit User Profile
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    eventKey="view"
                                    data-id={row.teamMemberId}
                                  >
                                    View User
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    eventKey={
                                      viewUserStatus === "active"
                                        ? "deactivate"
                                        : "reactivate"
                                    }
                                    data-id={row.teamMemberId}
                                  >
                                    {viewUserStatus === "active"
                                      ? "Deactivate User"
                                      : "Reactivate User"}
                                  </Dropdown.Item>
                                </>
                              ) : (
                                <>
                                  <Dropdown.Item
                                    eventKey="edit"
                                    data-id={row.teamMemberId}
                                  >
                                    Edit User Profile
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    eventKey="view"
                                    data-id={row.teamMemberId}
                                  >
                                    View User
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    eventKey="invite"
                                    data-id={row.teamMemberId}
                                  >
                                    Resend Invitation
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    eventKey={
                                      viewUserStatus === "active"
                                        ? "deactivate"
                                        : "reactivate"
                                    }
                                    data-id={row.teamMemberId}
                                  >
                                    {viewUserStatus === "active"
                                      ? "Deactivate User"
                                      : "Reactivate User"}
                                  </Dropdown.Item>
                                </>
                              )}
                            </Dropdown.Menu>
                          </div>
                        </Dropdown>
                      </TableCell>
                    </TableRow>
                  ))
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        count={filteredRows ? filteredRows.length : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />

      {/* **********************************************************************
       * Deactivate User Confirmation Modal
       ***********************************************************************/}
      <ConfirmationModal
        show={showChangeStatusModal}
        onHide={() => setShowChangeStatusModal(false)}
        headerText="Deactivate User"
        bodyText={`This user will no longer have access to their Teams profile.`}
        handleConfirm={handleDeactivateUser}
      />

      {/* **********************************************************************
       * Invitation Resent Confirmation Modal
       ***********************************************************************/}
      <ConfirmationModal
        show={inviteSentSuccessfully}
        onHide={resetInvitesSentSuccessfully}
        headerText="Invitation Sent"
        bodyText={`An invitation to join Teams was sent to ${selectedUser?.emailAddress}.`}
        handleConfirm={resetInvitesSentSuccessfully}
        showClose={true}
      />

      {/* **********************************************************************
       * Edit User Modal
       ***********************************************************************/}
      <EditUserModal
        showEditModal={showEditModal}
        selectedUser={selectedUser}
        setShowEditModal={(value) => setShowEditModal(value)}
      />
    </>
  );
};

export default UsersTable;
