import React, { useState, useEffect } from "react";
import { Modal, Col, Form } from "react-bootstrap";
import { LinkButton } from "app/components/LinkButton";
import { TeamMembersData } from "./types";
import { validateEmail } from "./helper-functions";
import { useSelector } from "react-redux";
import { selectTeamLeadErrorMessage } from "./selectors";
import { AddTeamLeadInput } from "app/components/AddTeamLeadInput";

interface Props {
  show: boolean;
  onHide: () => void;
  handleConfirm: (formData) => void;
  teamMembers: TeamMembersData[] | null;
  teamLeadChangeSuccessful: boolean;
  resetTeamLeadSuccessful: () => void;
  allowedDomains: string[];
  usersList?: Users[] | null;
}

interface Users {
  emailAddress: string;
  firstName: string;
  lastName: string;
}

export function TeamLeadModal({
  show,
  onHide,
  handleConfirm,
  teamMembers,
  teamLeadChangeSuccessful,
  resetTeamLeadSuccessful,
  allowedDomains,
  usersList,
}: Props) {
  const errorMessage = useSelector(selectTeamLeadErrorMessage);
  const [leadValue, setLeadValue] = useState("");
  const [leadValidationError, setLeadValidationError] = useState("");

  useEffect(() => {
    if (errorMessage) {
      setLeadValidationError(errorMessage);
    }
    return () => {
      setLeadValidationError("");
    };
  }, [errorMessage]);

  /****************************************************************************
   * Handlers                                                                 *
   ****************************************************************************/

  const handleCancel = () => {
    setLeadValue("");
    setLeadValidationError("");
    resetTeamLeadSuccessful();
    onHide();
  };

  const handleSendInvitation = () => {
    const isValidEmail = validateEmail(leadValue, allowedDomains);
    if (isValidEmail) {
      const formData = {
        email: leadValue,
        subTestIds: [43],
      };
      handleConfirm(formData);
    } else {
      setLeadValidationError(`${leadValue} is not a valid email address`);
    }
  };

  return (
    <Modal show={show} size="lg" onHide={onHide}>
      <Modal.Header closeButton className="modal-header">
        Assign New Team Lead
      </Modal.Header>
      <Modal.Body className="modal-text">
        {!teamLeadChangeSuccessful ? (
          <>
            <Form.Label htmlFor="new-team-lead" className="edit-form-label">
              New Team Lead
            </Form.Label>
            <AddTeamLeadInput
              usersList={usersList}
              allowedDomains={allowedDomains}
              leadValue={leadValue}
              setLeadValue={setLeadValue}
              leadValidationError={leadValidationError}
              setLeadValidationError={setLeadValidationError}
            />
            {leadValidationError ? (
              <p className="error-message mt-0">*{leadValidationError}.</p>
            ) : null}

            <p className="mt-3">
              Once you assign this user as the Team Lead, you will no longer be
              Team Lead. You will remain part of the team.
            </p>
          </>
        ) : (
          <p>{`An invitation has been sent to ${leadValue} `}</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Col className="text-right">
          <LinkButton
            text={`${teamLeadChangeSuccessful ? "Close" : "Cancel"}`}
            showArrow=""
            handler={handleCancel}
            isLoading={false}
            style={{
              fontFamily: "Lato",
              fontSize: "14px",
              lineHeight: "1.43",
              color: "#425cc7",
            }}
          />
          {!teamLeadChangeSuccessful ? (
            <button
              className="modal-button ml-5 pl-3 pr-3"
              onClick={handleSendInvitation}
            >
              Send Invitation
            </button>
          ) : null}
        </Col>
      </Modal.Footer>
    </Modal>
  );
}
