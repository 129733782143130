/**
 *
 * TeamPersonality
 *
 */

import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useSelector, useDispatch } from "react-redux";

import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import { reducer, sliceKey, actions } from "./slice";
import {
  selectTeamPersonality,
  selectTeamPersonalityReports,
  selectMemberPersonalityReports,
  selectTeamPersonalityPDF,
} from "./selectors";
import { teamPersonalitySaga } from "./saga";
import { useParams } from "react-router-dom";
import useEffectOnMount from "utils/custom_hooks/useEffectOnMount";
import { Container } from "react-bootstrap";
import "./styles.css";
import { Header } from "./Header";
import { TeamSummary } from "./TeamSummary";
import { SampleDataBanner } from "app/components/SampleDataBanner";
import { PersonalityReports } from "./types";
import { MemberPersonalityReport } from "./types";
import { selectNotableTraits, selectTeam } from "./selectors";
import { TeamData } from "./types";
import { isTeamLead } from "utils/helper_functions/isTeamLead";
import { Breadcrumb, Row, Col } from "react-bootstrap";
import downloadIcon from "../../../resources/images/download.svg";
import useSegmentTracking from "../../../utils/custom_hooks/useSegmentTracking";
import { selectUserAccountId } from "app/containers/Global/selectors";

interface Props {}

export function TeamPersonality(props: Props) {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: teamPersonalitySaga });

  /* get the value of teamId from the url params, to be used to get the team-related data */
  const { teamId }: any = useParams();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const teamPersonality = useSelector(selectTeamPersonality);
  const { teamPersonalityPDF, loadingPersonalityPDF } = useSelector(
    selectTeamPersonalityPDF
  );
  const teamPersonalityReports: PersonalityReports | null = useSelector(
    selectTeamPersonalityReports
  );
  const memberPersonalityReports:
    | MemberPersonalityReport[]
    | null = useSelector(selectMemberPersonalityReports);
  const notableTraits: string[] = useSelector(selectNotableTraits);
  const team: TeamData | null = useSelector(selectTeam);
  const userAccountId = useSelector(selectUserAccountId);
  const dispatch = useDispatch();

  /* useEffect Hooks */
  useEffectOnMount(() => {
    dispatch(actions.getPersonalityReports(teamId));
    dispatch(actions.getTeams(teamId));
  });
  // Download team personality report as a PDF
  // reference: https://medium.com/octopus-labs-london/downloading-a-base-64-pdf-from-an-api-request-in-javascript-6b4c603515eb
  useEffect(() => {
    if (teamPersonalityPDF) {
      const data = teamPersonalityPDF.data;
      const linkSource = `data:application/pdf;base64,${data[0]}`;
      const downloadLink = document.createElement("a");
      const fileName = "Team Personality Report - " + data.teamName;

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
      downloadLink.remove();
    }
    return () => {
      dispatch(actions.resetTeamPersonalityPDF());
    };
  }, [teamPersonalityPDF, dispatch]);

  const loggedInUserIsTeamLead = isTeamLead(team?.teamMembers, userAccountId);
  const downloadReport = () => {
    dispatch(actions.getPersonalityPDF(teamId));
  };

  useSegmentTracking(
    "team insights", // page name
    teamPersonalityReports !== null, // isDataLoaded
    teamPersonalityReports, // pageHasContentToShow
    teamId
  );

  return (
    <>
      <Helmet>
        <title>Team Personality</title>
        <meta
          name="description"
          content="Displays the Team Personality Summary"
        />
      </Helmet>
      {team?.isSample === 1 && <SampleDataBanner />}
      <Container fluid="lg">
        <div className="my-3 pb-3">
          <Breadcrumb className="breadcrumb">
            <Breadcrumb.Item
              href={`${process.env.REACT_APP_URL}/team/${team?.teamId}`}
            >
              {team?.teamName}
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Team Personality</Breadcrumb.Item>
          </Breadcrumb>
          <Row>
            <Col className="text-right">
              <button
                className="download-button"
                onClick={downloadReport}
                style={{
                  color: "#001F4E",
                }}
                disabled={loadingPersonalityPDF}
              >
                <img
                  className="mr-2 mb-1"
                  src={downloadIcon}
                  alt="download report"
                />
                {loadingPersonalityPDF ? "Loading..." : "Download"}
              </button>
            </Col>
          </Row>
          <Header />
          <TeamSummary
            teamPersonalityReports={teamPersonalityReports}
            memberPersonalityReports={memberPersonalityReports}
            notableTraits={notableTraits}
            viewAllWorkplaceInsights={team?.viewAllWorkplaceInsights}
            loggedInUserIsTeamLead={loggedInUserIsTeamLead}
            teamId={team?.teamId}
            teamName={team?.teamName}
          />
        </div>
      </Container>
    </>
  );
}
