import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "types";
import { initialState } from "./slice";

const selectDomain = (state: RootState) => state.myProfile || initialState;

export const selectMyProfile = createSelector(
  [selectDomain],
  (myProfileState) => myProfileState
);

export const selectUser = createSelector(
  [selectDomain],
  (myProfileState) => myProfileState.user
);

export const selectPendingTests = createSelector(
  [selectDomain],
  (myProfileState) => myProfileState.pendingTests
);

export const selectPersonalityProfile = createSelector(
  [selectDomain],
  (myProfileState) => myProfileState.personalityProfile
);

export const selectPersonalityProfileLoaded = createSelector(
  [selectDomain],
  (myProfileState) => myProfileState.personalityProfileLoaded
);

export const selectShowPersonalityProfile = createSelector(
  [selectDomain],
  (myProfileState) => myProfileState.showPersonalityProfile
);

export const selectNoEPP = createSelector(
  [selectDomain],
  (myProfileState) => myProfileState.noEPP
);

export const selectNoPermission = createSelector(
  [selectDomain],
  (myProfileState) => myProfileState.noPermission
);

export const selectLoggedInUserPendingTests = createSelector(
  [selectDomain],
  (myProfileState) => myProfileState.loggedInUserPendingTests
);

export const selectCollaborationGuidePDF = createSelector(
  [selectDomain],
  (myProfileState) => {
    return {
      collaborationGuidePDF: myProfileState.collaborationGuidePDF,
      loadingCollaborationGuidePDF: myProfileState.loadingCollaborationGuidePDF,
    };
  }
);
