import React from "react";
import { useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { selectNotableTraits } from "../selectors";
import { User } from "../types";
import { NotableTraits } from "utils/types/global-types";
import { getNotableTraitCards } from "utils/helper_functions/getNotableTraitCards";
// import { useCookies } from "react-cookie";

interface Props {
  user: User | null;
}

export function NotableTraitCards({ user }: Props) {
  // const [cookies] = useCookies(["name"]);
  const notableTraits: NotableTraits[] | null | undefined = useSelector(
    selectNotableTraits
  );
  const notableTraitCards = getNotableTraitCards(notableTraits);

  const loggedInUserAcccountId = user?.userAccountId;

  return (
    <div className="trait-summary-card">
      {/* eslint-disable-next-line */}
      <a className="anchor" id="NotableTraits"></a>
      <Row>
        <Col className="d-flex">
          <p className="trait-name mb-1">Notable Traits</p>
        </Col>
      </Row>
      <Row>
        <Col className="d-flex">
          <p className="subhead-italics mt-1">{`${
            loggedInUserAcccountId === user?.userAccountId
              ? "You"
              : user?.firstName
          } can be described as:`}</p>
        </Col>
      </Row>
      <Row>{notableTraitCards}</Row>
    </div>
  );
}
