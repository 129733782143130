/**
 *
 * AdminConsole
 *
 */

import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useSelector, useDispatch } from "react-redux";
import {
  Col,
  Container,
  Form,
  Row,
  ToggleButton,
  ToggleButtonGroup,
} from "react-bootstrap";
import { Tabs, Tab } from "@material-ui/core";
import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import { reducer, sliceKey, actions } from "./slice";
import {
  selectAdminConsole,
  selectTmgAllowUsersCreateTeam,
  selectAllowedDomains,
  selectUsersInvited,
  selectExistingUsers,
  selectAllowUsersShareInviteLink,
  selectHasSSOHardEnabled,
  selectInviteLink,
  selectNewLinkGenerated,
  selectAllowLinkResults,
} from "./selectors";
import { adminConsoleSaga } from "./saga";
import "./styles.css";
import TabPanel from "./TabPanel";
import useEffectOnMount from "utils/custom_hooks/useEffectOnMount";
import TeamsTable from "./Tables/TeamsTable";
import plusIcon from "resources/images/plus.svg";
import { useHistory } from "react-router-dom";
import UsersTable from "./Tables/UsersTable";
import InviteUsersModal from "./InviteUsersModal";
import CompanySettings from "./CompanySettings";
import InviteUsersConfirmationModal from "./InviteUsersConfirmationModal";
import {
  selectUser,
  selectCompanyAccountId,
} from "app/containers/Global/selectors";

interface FormData {
  emails: string[];
  subTestIds: number[];
}

export function AdminConsole() {
  useInjectReducer({
    key: sliceKey,
    reducer: reducer,
  });
  useInjectSaga({
    key: sliceKey,
    saga: adminConsoleSaga,
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const adminConsole = useSelector(selectAdminConsole);
  const originalTmgAllowUsersCreateTeam: number = useSelector(
    selectTmgAllowUsersCreateTeam
  );
  const originalAllowUsersShareInviteLink: number = useSelector(
    selectAllowUsersShareInviteLink
  );
  const originalAllowLinkResults: number = useSelector(selectAllowLinkResults);
  const hasSSOHardEnabled: boolean = useSelector(selectHasSSOHardEnabled);
  const allowedDomains: string[] = useSelector(selectAllowedDomains);
  const usersInvited: boolean = useSelector(selectUsersInvited);
  const existingUsers: any[] = useSelector(selectExistingUsers);
  const inviteLink: string = useSelector(selectInviteLink);
  const newLinkGenerated: boolean = useSelector(selectNewLinkGenerated);
  const user = useSelector(selectUser);
  const companyAccountId = useSelector(selectCompanyAccountId);

  // initialize the useHistory hook`
  const history = useHistory();

  const [value, setValue] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [viewUserStatus, setViewUserStatus] = useState("active");
  const [showAddUsersModal, setShowAddUsersModal] = useState(false);
  const [tmgAllowUsersCreateTeam, setTmgAllowUsersCreateTeam] = useState(
    originalTmgAllowUsersCreateTeam
  );
  const [allowUsersShareInviteLink, setAllowUsersShareInviteLink] = useState(
    originalAllowUsersShareInviteLink
  );
  const [allowLinkResults, setAllowLinkResults] = useState(
    originalAllowLinkResults
  );

  /****************************************************************************
   * Hooks                                                                    *
   ****************************************************************************/
  const dispatch = useDispatch();

  // get the teams data for initial display of page
  useEffectOnMount(() => {
    if (user) {
      if (user.tmgRoleId !== 1) history.push("/");
      if (user.companyAccountId) {
        const companyAccountId: number = user.companyAccountId;
        dispatch(actions.getCompanyData(companyAccountId));
      }
    }
    dispatch(actions.getTeams());
    // dispatch(actions.getExistingUsers());
  });

  useEffect(() => {
    setTmgAllowUsersCreateTeam(originalTmgAllowUsersCreateTeam);
  }, [originalTmgAllowUsersCreateTeam]);

  useEffect(() => {
    setAllowUsersShareInviteLink(originalAllowUsersShareInviteLink);
  }, [originalAllowUsersShareInviteLink]);

  useEffect(() => {
    setAllowLinkResults(originalAllowLinkResults);
  }, [originalAllowLinkResults]);

  /****************************************************************************
   * Handlers                                                                 *
   ****************************************************************************/
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const handleCreateTeamClick = () => {
    history.push("/create", { from: "admin" });
  };

  const handleInviteLeadsClick = () => {
    setShowAddUsersModal(true);
  };

  // Handler for allowing users to create teams
  const handleAllowUsersToCreateTeams = (checked: boolean) => {
    const numberValue = checked ? 1 : 0;
    setTmgAllowUsersCreateTeam(numberValue);
    const formData = {
      tmgAllowUsersCreateTeam: numberValue,
    };
    if (companyAccountId) {
      dispatch(actions.saveSettings(formData, companyAccountId));
    }
  };

  // Handler for allowing users to create teams
  const handleAllowUsersShareInviteLink = (checked: boolean) => {
    const numberValue = checked ? 1 : 0;
    setAllowUsersShareInviteLink(numberValue);
    const formData = {
      allowUsersShareInviteLink: numberValue,
    };
    if (companyAccountId) {
      dispatch(actions.saveSettings(formData, companyAccountId));
    }
  };

  // Handler for allowing users to create teams
  const handleAllowLinkResults = (checked: boolean) => {
    const numberValue = checked ? 1 : 0;
    setAllowLinkResults(numberValue);
    const formData = {
      allowLinkResults: numberValue,
    };
    if (companyAccountId) {
      dispatch(actions.saveSettings(formData, companyAccountId));
    }
  };

  const handleAddUsers = (formData: FormData) => {
    setShowAddUsersModal(false);
    dispatch(actions.addUsers(formData));
  };

  const resetUsersInvited = () => {
    dispatch(actions.resetUsersInvited());
  };

  const resetNewLinkGenerated = () => {
    dispatch(actions.updateNewLinkGenerated(false));
  };

  const deactivateLink = () => {
    if (companyAccountId) {
      dispatch(actions.deactivateLink(companyAccountId));
    }
  };

  return (
    <>
      <Helmet>
        <title>Admin Console</title>
        <meta name="description" content="Admin console" />
      </Helmet>
      <Container className="admin-container">
        <Row>
          <Col>
            <p className="header-text">Admin Console</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Tabs
              value={value}
              indicatorColor="primary"
              onChange={handleChange}
              aria-label="admin options"
              className="tabs-override"
            >
              <Tab label="Users" disableRipple={true} />
              <Tab label="Teams" disableRipple={true} />
              <Tab label="Settings" disableRipple={true} />
            </Tabs>
          </Col>
        </Row>
        <Row>
          <Col lg={7} md={7} sm={7}>
            {value === 0 ? (
              <ToggleButtonGroup
                name="status"
                className="users-toggle mt-3"
                value={viewUserStatus}
                onChange={(event: string) => setViewUserStatus(event)}
              >
                <ToggleButton
                  variant="light"
                  value={"active"}
                  id="active"
                  style={{
                    minWidth: "94px",
                    borderRightColor: "#b1b3b3",
                  }}
                >
                  Active
                  <span className="ml-2">
                    {viewUserStatus === "active" ? `•` : ``}
                  </span>
                </ToggleButton>
                <ToggleButton variant="light" value={"inactive"} id="inactive">
                  Inactive{" "}
                  <span className="ml-2">
                    {viewUserStatus === "inactive" ? `•` : ``}
                  </span>
                </ToggleButton>
              </ToggleButtonGroup>
            ) : null}
          </Col>
          <Col lg={3} md={3} sm={3} className="text-right">
            <Form.Control
              type="text"
              onChange={(e) => setSearchValue(e.target.value)}
              placeholder="Search"
              style={{ height: "40px" }}
            />
          </Col>
          <Col lg={2} md={2} sm={2} className="text-left">
            <button
              className="teal-button"
              onClick={
                value === 1 ? handleCreateTeamClick : handleInviteLeadsClick
              }
              style={{
                minWidth: "100%",
                margin: "auto",
              }}
            >
              <img src={plusIcon} className="mr-2" alt="create team" />
              {value === 1 ? "Create Team" : "Invite Users"}
            </button>
          </Col>
        </Row>
        <Row
          className="mt-3"
          style={{
            maxHeight: "100vh",
            overflowY: "auto",
          }}
        >
          <Col>
            <TabPanel value={value} index={0}>
              <UsersTable
                searchValue={searchValue}
                viewUserStatus={viewUserStatus}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <TeamsTable searchValue={searchValue} />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <CompanySettings
                handleAllowUsersToCreateTeams={handleAllowUsersToCreateTeams}
                handleAllowUsersShareInviteLink={
                  handleAllowUsersShareInviteLink
                }
                handleAllowLinkResults={handleAllowLinkResults}
                tmgAllowUsersCreateTeam={tmgAllowUsersCreateTeam}
                allowUsersShareInviteLink={allowUsersShareInviteLink}
                allowLinkResults={allowLinkResults}
                hasSSOHardEnabled={hasSSOHardEnabled}
              />
            </TabPanel>
          </Col>
        </Row>
      </Container>
      <InviteUsersModal
        show={showAddUsersModal}
        onHide={() => setShowAddUsersModal(false)}
        tmgAllowUsersCreateTeam={tmgAllowUsersCreateTeam}
        allowedDomains={allowedDomains}
        handleAddUsers={handleAddUsers}
        existingUsers={existingUsers}
        inviteLink={inviteLink}
        deactivateLink={deactivateLink}
        newLinkGenerated={newLinkGenerated}
        resetNewLinkGenerated={resetNewLinkGenerated}
        allowUsersShareInviteLink={allowUsersShareInviteLink}
        hasSSOHardEnabled={hasSSOHardEnabled}
      />
      <InviteUsersConfirmationModal
        show={usersInvited}
        onHide={resetUsersInvited}
      />
    </>
  );
}
