import { ProfileTypes, ProfileCardObjects } from "./types";
import communicationIcon from "resources/images/icon-communicationstyle.svg";
import workStyleIcon from "resources/images/icon-workstyle.svg";
import thinkingStyleIcon from "resources/images/icon-decisionmakingstyle.svg";

export const getDescriptions = (profile: ProfileTypes[]) => {
  const descriptionsArray = profile.map((profileObj) => {
    return profileObj.description;
  });
  return descriptionsArray;
};

export const getTips = (profile: ProfileTypes[] | undefined) => {
  if (profile) {
    const tipsArray = profile.map((profileObj) => {
      return profileObj.tip;
    });
    return tipsArray;
  }
  return [];
};

export const showTips = (tips: any[]) => {
  const validTips = tips.filter((tip) => tip !== undefined);
  return validTips.length > 0;
};

export const profileCardTitles: ProfileCardObjects[] = [
  {
    title: "Communication Style",
    key: "Communication",
    icon: communicationIcon,
  },
  {
    title: "Work Style",
    key: "WorkStyle",
    icon: workStyleIcon,
  },
  {
    title: "Thinking Style",
    key: "Decision-Making",
    icon: thinkingStyleIcon,
  },
];
