import React, { useState, useEffect } from "react";
import { Modal, Row, Col, Button, ButtonGroup } from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  Tabs,
  Tab,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import TabPanel from "./TabPanel";
import { AddUserInput } from "app/components/AddUserInput";
import { LinkButton } from "app/components/LinkButton";
import rightArrow from "resources/images/arrow-right-white.svg";
import copyLinkIcon from "resources/images/copy-link-icon.svg";
import linkCopiedIcon from "resources/images/link-copied-icon.svg";
import caretUp from "resources/images/caret-up.svg";
import caretDown from "resources/images/caret-down.svg";
import newLinkGeneratedIcon from "resources/images/new-link-generated-icon.svg";
import { isValidEmail } from "utils/helper_functions/isValidEmail";
import { useSelector } from "react-redux";
import { selectUser } from "app/containers/Global/selectors";

interface Props {
  show: boolean;
  onHide: () => void;
  tmgAllowUsersCreateTeam: number;
  allowedDomains: string[];
  handleAddUsers: (formData: any) => void;
  existingUsers: any;
  inviteLink: string;
  deactivateLink: () => void;
  newLinkGenerated: boolean;
  resetNewLinkGenerated: () => void;
  allowUsersShareInviteLink: number;
  hasSSOHardEnabled: boolean;
}

export default function InviteUsersModal({
  show,
  onHide,
  tmgAllowUsersCreateTeam,
  allowedDomains,
  handleAddUsers,
  existingUsers,
  inviteLink,
  deactivateLink,
  newLinkGenerated,
  resetNewLinkGenerated,
  allowUsersShareInviteLink,
  hasSSOHardEnabled,
}: Props) {
  /* Style constants */
  const linkButtonStyle = {
    fontFamily: "Lato",
    fontSize: "14px",
    lineHeight: "1.43",
    color: "#425cc7",
  };

  /****************************************************************************
   * State variables                                                          *
   ****************************************************************************/
  const [emailAddresses, setEmailAddresses] = useState<string[]>([]);
  const [validationErrors, setValidationErrors] = useState<any>({});
  const [tempInputValue, setTempInputValue] = useState("");
  const [tempInputValidationError, setTempInputValidationError] = useState("");
  const [existingUserEmails, setExistingUserEmails] = useState<string[]>([]);
  const [value, setValue] = useState(0);
  const [copied, setCopied] = useState(false);
  const [accordionOpen, setAccordionOpen] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  /****************************************************************************
   * Selectors                                                                *
   ****************************************************************************/

  const user = useSelector(selectUser);

  /****************************************************************************
   * Hooks                                                                    *
   ****************************************************************************/

  // update the state based on if the user is an admin or not.  we do this so that the
  // button does not flash on initial load for users that are not suppose to see it.
  useEffect(() => {
    setIsAdmin(user?.tmgRoleId === 1);
  }, [user]);

  useEffect(() => {
    if (hasSSOHardEnabled || (!allowUsersShareInviteLink && !isAdmin)) {
      setValue(1);
    }
  }, [hasSSOHardEnabled, allowUsersShareInviteLink, isAdmin]);

  // in order to validate the entered objects based on whether or not the entered
  // email is already a Criteria user, we need to create an array of existing user
  // email addresses that we can then validate against.
  useEffect(() => {
    if (existingUsers) {
      const existingEmailAddresses = existingUsers.map((user) => {
        return user.emailAddress;
      });
      setExistingUserEmails(existingEmailAddresses);
    }
  }, [existingUsers]);

  // remove temp validation error if the user has removed all temp input values
  useEffect(() => {
    if (tempInputValue === "" && tempInputValidationError !== "") {
      setTempInputValidationError("");
    }
  }, [tempInputValue, tempInputValidationError]);

  /****************************************************************************
   * Handlers                                                                 *
   ****************************************************************************/
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const handleCancelButton = () => {
    setEmailAddresses([]);
    setValidationErrors({});
    onHide();
  };

  const handleAccordionChange = (panel: string) => (
    event: React.ChangeEvent<{}>,
    isExpanded: boolean
  ) => {
    setAccordionOpen(isExpanded);
  };

  const handleCopyButton = () => {
    resetNewLinkGenerated();
    setCopied(true);
  };

  const handleDeactivateLink = () => {
    setCopied(false);
    deactivateLink();
  };

  const handleSubmit = () => {
    // the following conditional in enacted if the user has typed in an entry and
    // clicked the Invite button without typing a delimiter
    if (tempInputValue) {
      if (isValidEmail(tempInputValue)) {
        if (
          !allowedDomains.includes(tempInputValue.split("@")[1].toLowerCase())
        ) {
          setTempInputValidationError(
            `${tempInputValue} is not included in your company domain.`
          );
          return false;
        }
        if (existingUserEmails.includes(tempInputValue)) {
          setTempInputValidationError(
            `${tempInputValue} is not included in your company domain.`
          );
          return false;
        }
      } else {
        setTempInputValidationError(`${tempInputValue} is not a valid email.`);
        return false;
      }
      const emailAddressesClone = emailAddresses;
      emailAddressesClone.push(tempInputValue);
      setEmailAddresses(emailAddressesClone);
    }

    const formData = {
      subTestIds: [43],
      emails: emailAddresses,
    };
    handleAddUsers(formData);
  };

  return (
    <Modal show={show} onHide={onHide} id="invite-modal">
      <Modal.Header closeButton className="modal-header">
        Invite Users
      </Modal.Header>
      <Modal.Body>
        <p className="form-text">
          Invite colleagues to Criteria to start learning more about themselves
          and their teammates.
          {Boolean(tmgAllowUsersCreateTeam) &&
            ` They will be able to create their own teams and invite others members to join. `}
        </p>
        <Row>
          <Col>
            <Tabs
              value={value}
              indicatorColor="primary"
              onChange={handleChange}
              aria-label="admin options"
              className="invite-tab-override"
              variant="fullWidth"
            >
              <Tab
                label="Invite via Link"
                disableRipple={true}
                disabled={
                  hasSSOHardEnabled || (!allowUsersShareInviteLink && !isAdmin)
                }
              />

              <Tab label="Invite via Email" disableRipple={true} />
            </Tabs>
          </Col>
        </Row>
        {!hasSSOHardEnabled ? (
          allowUsersShareInviteLink || isAdmin ? (
            <TabPanel value={value} index={0}>
              <Row className="mt-5 text-center">
                <Col>
                  <ButtonGroup size="lg" className="mb-3">
                    <Button
                      variant="outline-primary"
                      style={{ fontSize: "14px", color: "#425cc7" }}
                      href={`${inviteLink}?`}
                      target="_blank"
                    >
                      {`${inviteLink?.substr(0, 59)}...`}
                    </Button>
                    <CopyToClipboard text={`${inviteLink}`}>
                      <Button
                        variant="primary"
                        onClick={handleCopyButton}
                        style={{
                          backgroundColor: newLinkGenerated
                            ? "#2dccd3"
                            : "#425cc7",
                          minWidth: "182px",
                          fontSize: "14px",
                        }}
                      >
                        <img
                          src={
                            newLinkGenerated
                              ? newLinkGeneratedIcon
                              : copied
                              ? linkCopiedIcon
                              : copyLinkIcon
                          }
                          alt="copy link"
                          height="16px"
                          width="16px"
                          className="mr-1"
                        />{" "}
                        {newLinkGenerated
                          ? "New Link Generated"
                          : copied
                          ? "Link Copied"
                          : "Copy Link"}
                      </Button>
                    </CopyToClipboard>
                  </ButtonGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Accordion onChange={handleAccordionChange("panel1")}>
                    <AccordionSummary
                      aria-controls="panel1a-content"
                      id="panel-header"
                    >
                      Want to deactivate this link?{" "}
                      <img
                        src={accordionOpen ? caretUp : caretDown}
                        alt="toggle"
                        height="24px"
                        width="24px"
                      />
                    </AccordionSummary>
                    <AccordionDetails style={{ fontSize: "16px" }}>
                      <Row>
                        <Col>
                          Are you sure you would like to deactivate your company
                          invite link? Anyone with the current link will not be
                          able to use that link. A new link will be generated
                          for you to share if you would still like to invite
                          users via link. This cannot be undone.
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col>
                          <button
                            id="deactivate-link-button"
                            onClick={handleDeactivateLink}
                          >
                            Deactivate Link
                          </button>
                        </Col>
                      </Row>
                    </AccordionDetails>
                  </Accordion>
                </Col>
              </Row>
            </TabPanel>
          ) : null
        ) : null}
        <TabPanel value={value} index={1}>
          <div className="mb-5">
            <AddUserInput
              usersList={null}
              allowedDomains={allowedDomains}
              emailAddresses={emailAddresses}
              setEmailAddresses={setEmailAddresses}
              validationErrors={validationErrors}
              setValidationErrors={setValidationErrors}
              existingUsers={existingUsers}
              tempInputValue={tempInputValue}
              setTempInputValue={setTempInputValue}
              tempInputValidationError={tempInputValidationError}
              displayPopper={false}
            />
          </div>
        </TabPanel>
      </Modal.Body>
      {value === 1 ? (
        <Modal.Footer>
          <Row>
            <Col
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className="text-center m-auto order-12 order-md-1"
            >
              <LinkButton
                text="Cancel"
                showArrow=""
                handler={handleCancelButton}
                isLoading={false}
                style={linkButtonStyle}
              />

              <button
                className="send-invites-button ml-5"
                disabled={Object.keys(validationErrors).length > 0}
                onClick={handleSubmit}
              >
                Send Invitations
                <img
                  src={rightArrow}
                  alt="right arrow"
                  className="create-team-arrow"
                />
              </button>
            </Col>
          </Row>
        </Modal.Footer>
      ) : null}
    </Modal>
  );
}
