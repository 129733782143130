/**
 *
 * ConfirmationModal
 *
 */
import React from "react";
import { Col, Modal } from "react-bootstrap";
import { LinkButton } from "app/components/LinkButton";

interface Props {
  show: boolean;
  onHide: () => void;
  headerText: string;
  bodyText: string;
  handleConfirm: () => void;
  showClose?: boolean;
}

export function ConfirmationModal({
  show,
  onHide,
  headerText,
  bodyText,
  handleConfirm,
  showClose,
}: Props) {
  return (
    <Modal show={show} size="lg" onHide={onHide}>
      <Modal.Header closeButton className="modal-header">
        {headerText}
      </Modal.Header>
      <Modal.Body className="modal-text">{bodyText}</Modal.Body>
      <Modal.Footer>
        <Col className="text-right">
          <LinkButton
            text="Cancel"
            showArrow=""
            handler={onHide}
            isLoading={false}
            style={{
              fontFamily: "Lato",
              fontSize: "14px",
              lineHeight: "1.43",
              color: "#425cc7",
            }}
          />
          <button
            className="modal-button ml-5 pl-3 pr-3"
            onClick={handleConfirm}
          >
            {showClose ? "Close" : headerText}
          </button>
        </Col>
      </Modal.Footer>
    </Modal>
  );
}
