import React from "react";
import { Col } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import usersInvitedIcon from "resources/images/email-invites.jpg";

interface Props {
  show: boolean;
  onHide: () => void;
}

export default function InviteUsersConfirmationModal({ show, onHide }: Props) {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header className="modal-header" closeButton>
        Your invitations have been sent!
      </Modal.Header>
      <Modal.Body className="modal-body">
        <Row className="mt-3 mb-3">
          <Col lg={4} md={4} sm={4} className="text-center">
            <img
              src={usersInvitedIcon}
              alt="users successfully invited"
              height="120px"
              width="120px"
            />
          </Col>
          <Col lg={8} md={8} sm={8}>
            <p className="modal-text">
              Invited users will receive an email with an invitation to join
              Criteria. Once they are logged in, they will be prompted to take a
              personality assessment.
            </p>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <button className="modal-button ml-5 pl-3 pr-3" onClick={onHide}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
}
