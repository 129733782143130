import { all, call, put, takeLatest } from "redux-saga/effects";
import { request } from "utils/request";
import { actions } from "./slice";
import jwtDecode from "jwt-decode";
import { PayloadAction } from "@reduxjs/toolkit";

export function* createAccount(action: PayloadAction<any>) {
  try {
    const requestUrl = `${process.env.REACT_APP_API_URL}/setupAccount`;
    const createAccountResponse = yield call(request, requestUrl, {
      method: "PUT",
      body: JSON.stringify(action.payload),
    });
    localStorage.setItem("tmg-tkn", createAccountResponse.jwt);
    const decodedToken: DecodedJWT = jwtDecode(createAccountResponse.jwt);
    const userAccountId: number = decodedToken.sub;
    yield put(actions.createAccountSuccess(userAccountId));
  } catch (error) {
    let errorMessage;
    if (error.response?.status === 400) errorMessage = "This link has expired";
    if (error.response?.status === 403)
      errorMessage = "This account requires SSO";
    yield put(actions.createAccountError(errorMessage));
  }
}

export function* acceptInvitation(action) {
  try {
    const requestUrl = `${process.env.REACT_APP_API_URL}/teaming/teamMembers/accept`;
    yield call(request, requestUrl, {
      method: "POST",
      body: JSON.stringify({
        token: action.payload.teamToken,
      }),
    });

    // if the user is not authenticated, redirect to login screen
    if (
      action.payload.authenticated !== undefined &&
      !action.payload.authenticated
    ) {
      localStorage.setItem("teamName", action.payload.teamName);
      localStorage.setItem("tid", action.payload.teamId);
      window.location.href = `${process.env.REACT_APP_LOGOUT_URL}`;
      // if user is authenticated, redirect to dashboard
    } else if (action.payload.authenticated) {
      window.location.href = `${process.env.REACT_APP_URL}?team=${action.payload.teamName}&tid=${action.payload.teamId}`;
    } else {
      yield put(actions.acceptInvitationSuccess());
    }
  } catch (error) {
    yield put(actions.acceptInvitationError(error.response.status));
  }
}

export function* accountSetupSaga() {
  yield all([
    takeLatest(actions.createAccount.type, createAccount),
    takeLatest(actions.acceptInvitation.type, acceptInvitation),
  ]);
}
