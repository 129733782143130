/**
 *
 * TeamList
 *
 */

import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useSelector, useDispatch } from "react-redux";
import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import { reducer, sliceKey, actions } from "./slice";
import {
  selectTeamMembers,
  selectTeamName,
  selectAllowedDomains,
  selectMembersInvited,
  selectUsersList,
  selectInvitingMembers,
} from "./selectors";
import { teamListSaga } from "./saga";
import { useParams } from "react-router-dom";
import useEffectOnMount from "utils/custom_hooks/useEffectOnMount";
import { Container } from "react-bootstrap";
import "./styles.css";
import { Header } from "./Header";
import { TeamMemberList } from "./TeamMemberList";
import { DeleteMemberConfirmationModal } from "./DeleteMemberConfirmationModal";
import { InviteCandidatesModal } from "app/components/InviteCandidatesModal";
import { InviteFormData } from "./types";
import { isTeamLead } from "utils/helper_functions/isTeamLead";
import { ConfirmationModal } from "app/components/ConfirmationModal";
import {
  selectUser,
  selectUserAccountId,
} from "app/containers/Global/selectors";

interface Props {}

export function TeamList(props: Props) {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: teamListSaga });

  /****************************************************************************
   * Selectors                                                               *
   ****************************************************************************/
  const teamName = useSelector(selectTeamName);
  const teamMembers = useSelector(selectTeamMembers);
  const allowedDomains = useSelector(selectAllowedDomains);
  const membersInvited = useSelector(selectMembersInvited);
  const usersList = useSelector(selectUsersList);
  const invitingMembers = useSelector(selectInvitingMembers);
  const user = useSelector(selectUser);
  const userAccountId = useSelector(selectUserAccountId);

  const dispatch = useDispatch();

  /* get the value of teamId from the url params, to be used to get the team-related data */
  const { teamId }: any = useParams();

  /****************************************************************************
   * State Variables                                                          *
   ****************************************************************************/
  const [teamMemberToDelete, setTeamMemberToDelete]: any = useState();
  const [teamMemberToInvite, setTeamMemberToInvite]: any = useState(null);
  const [
    showDeleteMemberConfirmation,
    setShowDeleteMemberConfirmation,
  ] = useState(false);
  const [currentUsersIsTeamLead, setCurrentUsersIsTeamLead] = useState(false);
  const [showInviteCandidateModal, setShowInviteCandidatesModal] = useState(
    false
  );
  const [
    showResendInviteConfirmation,
    setShowResendInviteConfirmation,
  ] = useState(false);
  /****************************************************************************
   * Hooks                                                                    *
   ****************************************************************************/

  useEffectOnMount(() => {
    if (teamId) {
      dispatch(actions.getTeamMembers(teamId));
    }
    const companyAccountId = user?.companyAccountId;

    if (companyAccountId) {
      dispatch(actions.getAllowedDomains(companyAccountId));
    }
    dispatch(actions.getUsersList());
  });

  useEffect(() => {
    if (teamMembers) {
      const userIsTeamLead = isTeamLead(teamMembers, userAccountId);
      setCurrentUsersIsTeamLead(userIsTeamLead);
    }
  }, [teamMembers, userAccountId]);

  /****************************************************************************
   * Handlers                                                                   *
   ******************************************************************************/

  const showRemoveMemberConfirmation = (teamMemberId: number | undefined) => {
    const selectedMember = teamMembers?.filter(
      (member) => member.teamMemberId === teamMemberId
    );
    const memberToDelete = {
      firstName: selectedMember?.[0].firstName,
      lastName: selectedMember?.[0].lastName,
      teamMemberId: selectedMember?.[0].teamMemberId,
    };
    setTeamMemberToDelete(memberToDelete);
    setShowDeleteMemberConfirmation(true);
  };

  const removeTeamMember = (teamMemberId: number) => {
    dispatch(actions.removeTeamMember(teamMemberId, teamId));
    setShowDeleteMemberConfirmation(false);
  };

  const InviteMembersButtonHandler = () => {
    setShowInviteCandidatesModal(true);
  };

  const handleInviteMembers = (formData: InviteFormData) => {
    dispatch(actions.inviteMembers(formData, teamId));
  };

  const hideInviteCandidatesModal = () => {
    dispatch(actions.resetMembersInvited());
    setShowInviteCandidatesModal(false);
  };

  const showResendInvitationConfirmation = (
    teamMemberId: number | undefined
  ) => {
    setTeamMemberToInvite(teamMemberId);
    setShowResendInviteConfirmation(true);
  };

  const hideResendInvitationModal = () => {
    setTeamMemberToInvite(null);
    setShowResendInviteConfirmation(false);
  };

  const resendInvitation = () => {
    dispatch(actions.resendInvitation(teamMemberToInvite));
    setTeamMemberToInvite(null);
    setShowResendInviteConfirmation(false);
  };

  return (
    <>
      <Helmet>
        <title>Team Members List</title>
        <meta name="description" content="List of Team Members" />
      </Helmet>
      <Container fluid="lg">
        <Header
          teamName={teamName}
          numberOfMembers={teamMembers?.length}
          teamId={teamId}
          InviteMembersButtonHandler={InviteMembersButtonHandler}
          currentUserIsTeamLead={currentUsersIsTeamLead}
        />
        <TeamMemberList
          teamMembers={teamMembers}
          showRemoveMemberConfirmation={showRemoveMemberConfirmation}
          showResendInviteConfirmation={showResendInvitationConfirmation}
          teamName={teamName}
          teamId={teamId}
        />
        <DeleteMemberConfirmationModal
          show={showDeleteMemberConfirmation}
          teamMemberToDelete={teamMemberToDelete}
          teamName={teamName}
          onHide={() => {
            setShowDeleteMemberConfirmation(false);
          }}
          removeTeamMember={removeTeamMember}
        />
        <InviteCandidatesModal
          show={showInviteCandidateModal}
          handleCancel={hideInviteCandidatesModal}
          teamId={teamId}
          allowedDomains={allowedDomains}
          handleInviteMembers={handleInviteMembers}
          membersInvited={membersInvited}
          usersList={usersList}
          teamMembers={teamMembers}
          invitingMembers={invitingMembers}
        />
        <ConfirmationModal
          show={showResendInviteConfirmation}
          onHide={hideResendInvitationModal}
          headerText={"Resend Invitation"}
          bodyText={`Are you sure sure you want to resend this invitation?`}
          handleConfirm={resendInvitation}
        />
      </Container>
    </>
  );
}
