/**
 *
 * GlobalNavBar
 *
 */
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import teamingIcon from "resources/images/criteria-logo.png";
import externalLinkIcon from "resources/images/external-link.svg";
import helpIcon from "resources/images/help-icon.png";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { useHistory, useLocation } from "react-router-dom";
import { Button, Dropdown, Media, NavItem } from "react-bootstrap";
import { useCookies } from "react-cookie";
import "./styles.css";
import { Avatar } from "../Avatar";
import { useWindowResize } from "beautiful-react-hooks";
import { useSelector } from "react-redux";
import {
  selectUser,
  selectUserInitials,
  selectUserName,
} from "app/containers/Global/selectors";

// interface User {
//   userName: string;
//   initials: string;
//   profilePicture: string;
//   avatarColor: string;
//   userAccountId: number;
//   emailAddress: string;
// }

export function GlobalNavBar() {
  const [hasHireSelectAccess, setHasHireSelectAccess] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [width, setWidth] = useState<number | null>(null);
  const [showNavBar, setShowNavBar] = useState(true);

  // set up the useCookies hook
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies(["name"]);
  const location = useLocation();

  /****************************************************************************
   * Selectors                                                               *
   ****************************************************************************/
  const user = useSelector(selectUser);
  const userInitials = useSelector(selectUserInitials);
  const userName = useSelector(selectUserName);

  useEffect(() => {
    if (user) {
      if (user.hireselectAccess) {
        setHasHireSelectAccess(true);
      }
      if (user.tmgRoleId === 1) {
        setIsAdmin(true);
      }
    }
  }, [user]);

  // if the url query string has teamToken but not token1 and token2, it means an existing user has
  // landed at the account setup page and will be immediately redirected, so they should not see the nav bar
  const redirectingExistingUserFromAccountSetup =
    location.search.includes("teamToken") &&
    !location.search.includes("token1") &&
    !location.search.includes("token2");

  /* to ensure the correct nav item is active upon refresh, we need to pull it from the location state */
  const currentPathArray = location.pathname.split("/");
  const currentPath = currentPathArray[1];
  const viewedUserAccountId = currentPathArray[2];

  // determine whether the user's url contains the string join or accountSetup. if so, that means they are on the create
  // account or account setup page, and we do not want to show the navbar options to them.
  const showNavOptions =
    currentPathArray.includes("join") ||
    currentPathArray.includes("accountSetup");

  /*
   * if the user is currently at a specific url, we want to keep that nav item active.  if not, default
   * to the dashboard
   */
  const [activeKey, setActiveKey] = useState(
    currentPath === "profile" ||
      (currentPath === "my_summary" &&
        String(user?.userAccountId) !== viewedUserAccountId)
      ? "profile"
      : "dashboard"
  );

  /* this useEffect insures the nav bar active key stays aligned with the navigation */
  useEffect(() => {
    if (String(user?.userAccountId) === viewedUserAccountId && currentPath)
      setActiveKey(
        currentPath === "profile" ||
          (currentPath === "my_summary" &&
            String(user?.userAccountId) !== viewedUserAccountId)
          ? "profile"
          : "dashboard"
      );
  }, [currentPath, user, viewedUserAccountId]);

  // define the ref we use to get the width of the nav bar element
  const navRef: any = useRef(null);

  /*
   * the layout effect hook will determine the width of the element that contains
   * the nav bar.  it is responsive, so it'll get called anytime there is a
   * change to the element size
   */
  useLayoutEffect(() => {
    if (navRef.current) {
      const currentWidth = Math.round(
        navRef?.current?.getBoundingClientRect().width
      );
      if (currentWidth > 768) {
        setShowNavBar(true);
      }
      setWidth(currentWidth);
    }
  }, [navRef, setShowNavBar]);

  useWindowResize(() => {
    if (navRef.current) {
      const currentWidth = Math.round(
        navRef?.current?.getBoundingClientRect().width
      );
      if (currentWidth > 768) {
        setShowNavBar(true);
      }
      setWidth(currentWidth);
    }
  });

  // we want to update the state whenever the browser goes above or below this
  // limit so we can structure the nav bar accordingly.
  useEffect(() => {
    if (width && width <= 768 && showNavBar) {
      setShowNavBar(false);
    }
  }, [width, showNavBar]);

  /****************************************************************************
   * Handlers                                                                 *
   ****************************************************************************/

  const history = useHistory();
  const handleSelect = (event: string | null) => {
    switch (event) {
      case "dashboard":
        setActiveKey("dashboard");
        window.location.href = `${process.env.REACT_APP_URL}`;
        break;
      case "profile":
        setActiveKey("profile");
        if (user?.userAccountId) {
          history.push(`/profile/${user?.userAccountId}`);
        }
        break;
      case "admin":
        setActiveKey("admin");
        if (isAdmin) {
          history.push(`/admin`);
        }
        break;
      case "hireselect":
        handleHireSelectButton();
        break;
      default:
        history.push("/");
        break;
    }
  };

  const handleUserSettings = (event: string | null) => {
    switch (event) {
      case "dashboard":
        setActiveKey("dashboard");
        window.location.href = `${process.env.REACT_APP_URL}`;
        break;
      case "profile":
        setActiveKey("profile");
        if (user?.userAccountId) {
          history.push(`/profile/${user?.userAccountId}`);
        }
        break;
      case "admin":
        setActiveKey("admin");
        if (isAdmin) {
          history.push(`/admin`);
        }
        break;
      case "accountSettings":
        history.push("account_settings");
        break;
      case "logout":
        localStorage.removeItem("tmg-tkn");
        history.push("/");
        break;
      default:
        history.push("/");
        break;
    }
  };

  const handleLogoClick = () => {
    history.push("/");
  };

  const handleHireSelectButton = () => {
    setCookie("critToken", localStorage.getItem("tmg-tkn"), {
      path: "/",
      domain: ".criteriacorp.com",
    });
    localStorage.removeItem("tmg-tkn");
    window.location.href = `${process.env.REACT_APP_HIRESELECT_URL}`;
  };

  const handleHelpClick = () => {
    const hostnameArray = window.location.hostname.split(".");
    hostnameArray[0].toUpperCase().includes("AU")
      ? window.open(`https://revelian.helpdocs.io/`, "au")
      : window.open(`https://criteria.helpdocs.io/`, "_blank");
  };

  /* styles for the user-settings icon */
  let avatarStyles: any = {
    height: `40px`,
    width: `40px`,
    fontSize: `21px`,
    lineHeight: `40px`,
    borderRadius: `50%`,
    backgroundColor: `#${user?.avatarColor}`,
    color: `#FFF`,
    fontFamily: `Lato`,
    display: `inline-block`,
  };

  return (
    <>
      {!redirectingExistingUserFromAccountSetup ? (
        <Navbar
          fixed="top"
          sticky="top"
          role="navigation"
          className="nav-bar"
          onSelect={handleSelect}
          collapseOnSelect
          ref={navRef}
        >
          <Navbar.Brand>
            <div onClick={handleLogoClick} id="criteria-logo-icon">
              <img src={teamingIcon} alt="Teaming Logo" id="teaming-logo" />
            </div>
          </Navbar.Brand>
          {/* <Navbar.Text className="nav-header">TEAMING</Navbar.Text> */}
          {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
          {showNavBar && !showNavOptions ? (
            // <Navbar.Collapse id="basic-navbar-nav">
            <Nav activeKey={activeKey}>
              <Nav.Link
                className="first-nav-link-item"
                eventKey="dashboard"
                style={{ paddingTop: "18px", paddingBottom: "18px" }}
              >
                Dashboard
              </Nav.Link>
              <Nav.Link
                className="nav-link-item"
                eventKey="profile"
                style={{ paddingTop: "18px", paddingBottom: "18px" }}
              >
                My Profile
              </Nav.Link>
              {isAdmin ? (
                <Nav.Link
                  className="nav-link-item"
                  eventKey="admin"
                  style={{ paddingTop: "18px", paddingBottom: "18px" }}
                >
                  Admin Console
                </Nav.Link>
              ) : null}
            </Nav>
          ) : // </Navbar.Collapse>
          null}
          {user ? (
            <Nav
              style={{
                position: "absolute",
                right: "10px",
              }}
            >
              {hasHireSelectAccess && showNavBar ? (
                <Button
                  className="hireselect-button mr-3"
                  variant="outline-light"
                  onClick={handleHireSelectButton}
                >
                  My Jobs{" "}
                  <img
                    src={externalLinkIcon}
                    className="ml-1 mb-1"
                    alt="link to HireSelect"
                  />
                </Button>
              ) : null}
              <Button
                variant="outline-none"
                style={{ padding: "0px" }}
                onClick={handleHelpClick}
              >
                <div
                  className="d-flex mt-auto mb-auto ml-2 mr-2"
                  id="help-icon-container"
                >
                  <img
                    src={helpIcon}
                    id="help"
                    height="24px"
                    width="24px"
                    alt="help"
                  />
                </div>
              </Button>
              <Dropdown as={NavItem} alignRight onSelect={handleUserSettings}>
                <Dropdown.Toggle id="user-settings">
                  <span>
                    {user?.profilePicture ? (
                      <img
                        src={user?.profilePicture}
                        alt="avatar"
                        height="40px"
                        width="40px"
                        className="mr-1"
                      />
                    ) : (
                      <div
                        style={avatarStyles}
                        className="text-center avatar-container"
                        id="avatar"
                      >
                        {user?.profilePicture ? (
                          <img src={user?.profilePicture} alt="avatar" />
                        ) : userInitials ? (
                          userInitials.toUpperCase()
                        ) : (
                          user?.emailAddress.slice(0, 2).toUpperCase()
                        )}
                      </div>
                    )}
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu id="user-settings-menu">
                  <Media
                    style={{ borderBottom: "1px solid #d8d9d9" }}
                    className="pb-2"
                  >
                    <div className="align-self-start mr-3">
                      <Avatar
                        height={40}
                        width={40}
                        fontSize={20}
                        userName={
                          userInitials?.toUpperCase() ||
                          user?.emailAddress.slice(0, 2).toUpperCase()
                        }
                        initials={
                          userInitials?.toUpperCase() ||
                          user?.emailAddress.slice(0, 2).toUpperCase()
                        }
                        image={user?.profilePicture}
                        emailAddress={user?.emailAddress}
                        backgroundColor={`#${user?.avatarColor}`}
                      />
                    </div>
                    <Media.Body>
                      <div className="user-name-header">{userName}</div>
                      <div className="user-email-address">
                        {user?.emailAddress}
                      </div>
                    </Media.Body>
                  </Media>
                  {!showNavBar ? (
                    <>
                      <Dropdown.Item eventKey="dashboard" className="mt-2 mb-1">
                        Dashboard
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="profile" className="mt-2 mb-1">
                        My Profile
                      </Dropdown.Item>
                      <Dropdown.Item
                        eventKey="hireselect"
                        className="mt-2 mb-1"
                      >
                        My Jobs
                      </Dropdown.Item>
                      {isAdmin ? (
                        <Dropdown.Item eventKey="admin" className="mt-2 mb-1">
                          Admin Console
                        </Dropdown.Item>
                      ) : null}
                    </>
                  ) : null}
                  <Dropdown.Item
                    eventKey="accountSettings"
                    className="mt-2 mb-1"
                  >
                    Account Settings
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="logout" className="mt-2 mb-1">
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav>
          ) : null}
        </Navbar>
      ) : null}
    </>
  );
}
