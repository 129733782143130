import React from "react";
import { Row, Col } from "react-bootstrap";
import { BrowserView, MobileView } from "react-device-detect";
import { PersonalityProfile } from "./types";
import { Avatar } from "app/components/Avatar";

interface Props {
  personalityProfile: PersonalityProfile | null;
  user: any;
}
export const CollaborationGuideHeader = ({
  personalityProfile,
  user,
}: Props) => {
  return (
    <div className="collaboration-guide-header-container">
      <BrowserView>
        <Row>
          <Col lg={10} md={9} sm={8} xs={8}>
            <p className="collaboration-guide-header">Collaboration Guide</p>

            {personalityProfile?.comparison ? (
              <p className="collaboration-guide-header-text">
                {`This guide provides an overview of how you and ${user?.firstName}
            compare in communication style, work style, and thinking
            style. Within the guide there are also tips to help you
            work more effectively together.`}
              </p>
            ) : (
              <p className="collaboration-guide-header-text">
                {`Once you complete your personality test, you will be able to see
              how you compare to ${user?.firstName} and see tips for working more effectively with them.`}
              </p>
            )}
          </Col>
          <Col lg={2} md={2} sm={3} xs={3} className="m-auto">
            <Row>
              <Avatar
                height={60}
                width={60}
                fontSize={24}
                userName={`${user?.firstName} ${user?.lastName}`}
                image={user?.profilePicture}
                initials={
                  user?.firstName
                    ? `${user?.firstName.charAt(0)}${user?.lastName.charAt(0)}`
                    : `${user?.emailAddress.slice(0, 2)}`
                }
                backgroundColor={`#${user?.avatarColor}`}
                shouldOverlap={true}
                showInGrayscale={!personalityProfile?.comparison}
              />
              <Avatar
                height={60}
                width={60}
                fontSize={24}
                userName={user?.firstName}
                image={user?.profilePicture}
                initials={
                  user?.firstName
                    ? `${user?.firstName.charAt(0)}${user?.lastName.charAt(0)}`
                    : `${user?.emailAddress.slice(0, 2)}`
                }
                backgroundColor={`#${user?.avatarColor}`}
                shouldOverlap={true}
              />
            </Row>
          </Col>
        </Row>
      </BrowserView>
      <MobileView>
        <Row>
          <Col lg={8} md={8} sm={8} xs={8}>
            <p className="collaboration-guide-header">Collaboration Guide</p>
          </Col>
          <Col lg={4} md={4} sm={4} xs={4}>
            <Row>
              <Avatar
                height={42}
                width={42}
                fontSize={18}
                userName={`${user?.firstName} ${user?.lastName}`}
                image={user?.profilePicture}
                initials={
                  user?.firstName
                    ? `${user?.firstName.charAt(0)}${user?.lastName.charAt(0)}`
                    : `${user?.emailAddress.slice(0, 2)}`
                }
                backgroundColor={`#${user?.avatarColor}`}
                shouldOverlap={true}
                showInGrayscale={!personalityProfile?.comparison}
              />
              <Avatar
                height={42}
                width={42}
                fontSize={18}
                userName={user?.firstName}
                image={user?.profilePicture}
                initials={
                  user?.firstName
                    ? `${user?.firstName.charAt(0)}${user?.lastName.charAt(0)}`
                    : `${user?.emailAddress.slice(0, 2)}`
                }
                backgroundColor={`#${user?.avatarColor}`}
                shouldOverlap={true}
              />
            </Row>
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={2} sm={12} xs={12}>
            {personalityProfile?.comparison ? (
              <p className="collaboration-guide-header-text">
                {`This guide provides an overview of how you and ${user?.firstName}
            compare in communication style, work style, and thinking
            style. Within the guide there are also tips to help you
            work more effectively together.`}
              </p>
            ) : (
              <p className="collaboration-guide-header-text">
                {`Once you complete your personality test, you will be able to see
              how you compare to ${user?.firstName} and see tips for working more effectively with them.`}
              </p>
            )}
          </Col>
        </Row>
      </MobileView>
    </div>
  );
};
