import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Dropdown } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { selectTeams } from "app/containers/AdminConsole/selectors";
import { getComparator, getTeamLead, stableSort } from "../helper-functions";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import { LinkButton } from "app/components/LinkButton";
import menu from "resources/images/menu.svg";

interface Props {
  searchValue: string;
}

interface Data {
  teamName: string;
  teamLead: string | null;
  numberOfMembers: number;
  teamId: string;
}

interface HeadCell {
  id: keyof Data;
  label: string;
}

type Order = "asc" | "desc";

const headCells: HeadCell[] = [
  { id: "teamName", label: "Team Name" },
  { id: "teamLead", label: "Team Lead" },
  { id: "numberOfMembers", label: "# of Team Members" },
];

function createData(
  teamName: string,
  teamLead: string | null,
  numberOfMembers: number,
  teamId: string
): Data {
  return {
    teamName,
    teamLead,
    numberOfMembers,
    teamId,
  };
}

const TeamsTable = ({ searchValue }: Props) => {
  // state variables
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof Data>("teamName");
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [page, setPage] = useState(0);

  // get access to the history hook
  const history = useHistory();

  // retrieve the object of teams in the redux store
  const teams = useSelector(selectTeams);

  // create the array of rows to be displayed in the table
  let rows;
  if (teams) {
    rows = Object.keys(teams).map((teamId) => {
      const teamLead = getTeamLead(teams[teamId].teamMembers) || "";
      return createData(
        teams[teamId].teamName,
        teamLead,
        teams[teamId].teamMembers.length,
        teamId
      );
    });
  }

  const filteredRows = rows?.filter((row) => {
    if (
      String(row.teamName).toLowerCase().includes(searchValue.toLowerCase()) ||
      String(row.teamLead).toLowerCase().includes(searchValue.toLowerCase())
    )
      return true;
    return false;
  });

  // handlers
  const handleNumberOfMembersClick = (teamId: string) => {
    history.push(`/team_list/${teamId}`);
  };

  const handleDropdown = (event, teamId) => {
    if (event === "view") {
      history.push(`/team/${teamId}`);
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  /****************************************************************************
   * Sorting Functions                                                        *
   ****************************************************************************/
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const createSortHandler = (property: keyof Data) => (
    event: React.MouseEvent<unknown>
  ) => {
    handleRequestSort(event, property);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <TableContainer
        style={{
          maxHeight: "70vh",
          minWidth: "768px",
          maxWidth: "1268px",
          overflow: "auto",
        }}
      >
        <Table
          aria-labelledby="Teams Table"
          size="medium"
          aria-label="teams table"
          stickyHeader={true}
        >
          <colgroup>
            <col style={{ width: "30%" }} />
            <col style={{ width: "30%" }} />
            <col style={{ width: "30%" }} />
            <col style={{ width: "10%" }} />
          </colgroup>
          <TableHead className="table-header">
            <TableRow>
              {headCells.map((headCell) => (
                <TableCell key={headCell.id} align="left">
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : "asc"}
                    onClick={createSortHandler(headCell.id)}
                  >
                    {headCell.label}
                  </TableSortLabel>
                </TableCell>
              ))}
              <TableCell key={"teamId"} align="left"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              ? stableSort(filteredRows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row" align="left">
                        {row.teamName}
                      </TableCell>
                      <TableCell align="left">{row.teamLead}</TableCell>
                      <TableCell align="left">
                        <LinkButton
                          text={String(row.numberOfMembers)}
                          handler={() => {
                            handleNumberOfMembersClick(String(row.teamId));
                          }}
                          showArrow=""
                        ></LinkButton>
                      </TableCell>
                      <TableCell align="right">
                        <Dropdown
                          onSelect={(event) =>
                            handleDropdown(event, row.teamId)
                          }
                        >
                          <div className="no-caret">
                            <Dropdown.Toggle size="sm" className="menu-button">
                              <img
                                src={menu}
                                alt="menu"
                                width="24"
                                height="24"
                              />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                eventKey="view"
                                data-id={row.teamId}
                              >
                                View Team
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </div>
                        </Dropdown>
                      </TableCell>
                    </TableRow>
                  ))
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        count={filteredRows?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
};

export default TeamsTable;
